import Navigation from "./Navigation";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import { useState } from "react";
// import { Spinner } from "../components";

export default function Layout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <Helmet>
        <title>HTC Accounting</title>
        <meta property="og:title" content="HTC Accounting" key="title" />
      </Helmet>
      <div className="flex min-h-screen relative w-full bg-slate-800">
        <div
          className={`main-sidebar group/sidebar-wrapper fixed left-0 top-0 z-[999] min-w-0 duration-100 ease-[cubic-bezier(0.2, 0, 0, 1)] ${
            isSidebarOpen ? "expanded w-56" : "collapsed w-12"
          }`}
        >
          <aside className="flex h-screen w-full flex-col overflow-y-hidden bg-slate-800">
            <Navigation
              toggleSidebar={toggleSidebar}
              isSidebarOpen={isSidebarOpen}
            />
          </aside>
        </div>
        <div
          className={`main-wrapper min-w-0 flex flex-col w-full bg-white ${
            isSidebarOpen ? "ml-56" : "ml-12"
          }`}
        >
          <header className="sticky top-0 z-[990] flex flex-col w-full bg-white shadow-md">
            {/* <Header toggleSidebar={toggleSidebar} /> */}
            {/* <Spinner /> */}
          </header>
          <main className="main-container p-3 min-w-0">
            <Outlet />
          </main>
        </div>
      </div>
      <Footer />
    </>
  );
}
