import { useEffect, useState } from "react";
import { Message } from "..";
import {
  inputHidden,
  inputCheckBox,
  inputSwitch,
  inputEmail,
  inputPassword,
  staticInputSelectState,
  inputText,
  staticInputSelect,
  inputDate,
  dynamicInputSelect,
  inputMultipleCheckBoxGroups,
  inputMultipleCheckBox,
  staticInputSelectCity,
  inputImgFile,
} from "../../utils/dynamicForm";
import useUploadHook from "../../api/upload";

const methodConversion = (methodName) => {
  switch (methodName) {
    case "GET":
      return "List";
    case "POST":
      return "Add";
    case "PUT":
      return "Edit";
    case "DELETE":
      return "Delete";
  }
};

export const FormChannelPartners = ({
  edit,
  view,
  formCleanHandler,
  isLoading,
  register,
  isError,
  errors,
  watch,
  isLoadingUpdate,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  error,
  setIsModalOpen,
  permissionData,
  menuData,
  nextSequenceNumber,
  states,
  cities,
  user,
  channelId,
}) => {
  const getDynamicLabel = (field, value) => {
    if (field === "blocked") {
      return value ? "Active" : "Inactive";
    }
    return field;
  };
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  useEffect(() => {
    const currentPermissions = watch("permission") || [];
    setCheckedPermissions(currentPermissions);
  }, [watch("permission")]);
  const [city, setCity] = useState([]);
  const [getTrue, setTrue] = useState(false);
  const [stateShortName, setStateShortName] = useState("");
  const [cityShortName, setCityShortName] = useState("");
  const [sequenceNumber, setSequenceNumber] = useState(1);
  const [companyID, setCompanyID] = useState("");
  const [fileLink, setFileLink] = useState(null);
  const handleStateChange = (e) => {
    setTrue(true);
    const id = e.target.selectedOptions[0].dataset.id;
    const shortName = e.target.selectedOptions[0].dataset.shortname;
    if (id !== "") {
      setStateShortName(shortName);
      const filteredCities = cities
        .filter((item) => item?.state?._id === id)
        .map((item) => ({
          name: item.cityName,
          shortName: item.cityShortName,
        }));
      setCity(filteredCities);
    } else {
      setCity([]);
    }
  };
  const handleCityChange = (e) => {
    const cityShortName = e.target.selectedOptions[0].dataset.shortname;
    setCityShortName(cityShortName);
  };
  const generateIntroductionID = () => {
    if (stateShortName && cityShortName && sequenceNumber > 0) {
      const formattedSequenceNumber = String(sequenceNumber).padStart(5, "0");
      const newCompanyID = `${stateShortName}${cityShortName}${formattedSequenceNumber}CP`;
      setCompanyID(newCompanyID);
      setSequenceNumber(sequenceNumber + 1);
    }
  };
  useEffect(() => {
    if (stateShortName && cityShortName) {
      generateIntroductionID();
    }
  }, [stateShortName, cityShortName]);

  //upload API
  const { postUpload } = useUploadHook();
  const {
    data: dataUpload,
    isLoading: isLoadingUpload,
    isError: isErrorUpload,
    error: errorUpload,
    mutateAsync: mutateAsyncUpload,
    isSuccess: isSuccessUpload,
  } = postUpload;

  const handleFile = (e) => {
    console.log("Event data:", e, e.target.files);
    const file = e.target.files && e.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }
    console.log("Event data:", file);
    const formData = new FormData();
    formData.append("file", file);

    console.log("Event data:");
    for (let pair of formData.entries()) {
      console.log(pair[0] + ":", pair[1]);
    }
    mutateAsyncUpload({ type: "image", formData })
      .then((response) => {
        console.log("Event data:", response);
        if (
          response &&
          response.filePaths &&
          response.filePaths[0] &&
          response.filePaths[0].path
        ) {
          setFileLink(response.filePaths[0].path);
        }
      })
      .catch((error) => {
        console.error("Event data:", error);
      });
  };
  useEffect(() => {
    if (stateShortName && cityShortName) {
      setCompanyID(
        `${stateShortName}${cityShortName}` +
          String(nextSequenceNumber > 0 ? nextSequenceNumber : 1).padStart(
            5,
            "0"
          ) +
          "CP"
      );
    }
  }, [stateShortName, cityShortName]);
  return (
    <>
      {isLoading ? (
        <div className="w-full top-0 left-0 z-[999]">
          <div className="h-1 w-full bg-blue-100 overflow-hidden">
            <span className="sr-only">Loading...</span>
            <div className="animate-progress w-full h-full bg-blue-500 origin-left-right"></div>
          </div>
        </div>
      ) : isError ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <form onSubmit={handleSubmit(submitHandler)}>
          {inputHidden({
            register,
            errors,
            label: "",
            name: "sequenceNumber",
            placeholder: "Sequence Number",
            value: nextSequenceNumber > 0 ? nextSequenceNumber : 1,
            readOnly: true,
          })}
          {inputHidden({
            register,
            errors,
            label: "",
            name: "userID",
            placeholder: "User ID",
            value:
              "USR" +
              String(nextSequenceNumber > 0 ? nextSequenceNumber : 1).padStart(
                5,
                "0"
              ),
            readOnly: true,
          })}
          {inputText({
            register,
            errors,
            label: "Channel Partner Code",
            name: "channelPartnerSerialNo",
            placeholder: "Channel Partner Code",
            value:
              "CPT" +
              String(nextSequenceNumber > 0 ? nextSequenceNumber : 1).padStart(
                5,
                "0"
              ),
            readOnly: true,
          })}
          <div className="grid grid-cols-12 gap-x-3">
            {inputDate({
              register,
              errors,
              label: "Registration Date",
              name: "registrationDate",
              placeholder: "11/11/1999",
              isRequired: true,
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
            {staticInputSelectState({
              register,
              errors,
              label: "State",
              name: "state",
              placeholder: "State",
              isRequired: false,
              data:
                states &&
                states.map((item) => ({
                  name: item.stateName,
                  _id: item._id,
                  shortName: item.stateShortName,
                })),
              onChange: handleStateChange,
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
            {staticInputSelectCity({
              register,
              errors,
              label: "City",
              name: "city",
              placeholder: "City",
              isRequired: false,
              data: edit && !getTrue ? [{ name: watch("city") }] : city && city,
              onChange: handleCityChange,
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
          </div>
          {/* {inputText({
            register,
            errors,
            label: "Introduction ID",
            name: "introductionID",
            placeholder: "TNCHN12345CO",
            isRequired: true,
            readOnly: view,
          })} */}
          <div className="grid grid-cols-12 gap-x-3">
            {staticInputSelect({
              register,
              errors,
              label: "Introduction ID",
              name: "introductionID",
              placeholder: "Introduction ID",
              isRequired: false,
              data: edit
                ? [
                    { name: watch("introductionID") },
                    ...(channelId
                      ? channelId
                          .filter(
                            (item) =>
                              item.channelPartnerID !== watch("introductionID")
                          )
                          .map((item) => ({
                            name: item.channelPartnerID,
                            _id: item._id,
                          }))
                      : []),
                  ]
                : channelId &&
                  channelId.map((item) => ({
                    name: item.channelPartnerID,
                    _id: item._id,
                  })),
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
            {view || edit ? (
              <>
                {inputText({
                  register,
                  errors,
                  label: "Channel Partner ID",
                  name: "channelPartnerID",
                  isRequired: false,
                  readOnly: true,
                  wrapperClass: "col-span-6",
                })}
              </>
            ) : (
              <>
                {inputText({
                  register,
                  errors,
                  label: "Channel Partner ID",
                  name: "channelPartnerID",
                  value: companyID || "TNCHN12345CP",
                  placeholder: "TNCHN12345CP",
                  isRequired: true,
                  readOnly: true,
                  wrapperClass: "col-span-6",
                })}
              </>
            )}
          </div>
          {/* {dynamicInputSelect({
            register,
            errors,
            label: "User ID",
            name: "userName",
            placeholder: "User ID",
            isRequired: true,
            data: user && user,
            value: "firstName",
            readOnly: view,
          })} */}
          <div className="grid grid-cols-12 gap-x-3">
            {inputText({
              register,
              errors,
              label: "First Name",
              name: "firstName",
              placeholder: "First Name",
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
            {inputText({
              register,
              errors,
              label: "Last Name",
              name: "lastName",
              placeholder: "Last Name",
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
          </div>
          <div className="grid grid-cols-12 gap-x-3">
            {inputText({
              register,
              errors,
              label: "Address 1",
              name: "address1",
              placeholder: "House/Flat no, Building name",
              isRequired: false,
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
            {inputText({
              register,
              errors,
              label: "Address 2",
              name: "address2",
              placeholder: "Street name/number",
              isRequired: false,
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
            {inputText({
              register,
              errors,
              label: "Address 3",
              name: "address3",
              placeholder: "Block no. , Area Name",
              isRequired: false,
              readOnly: view,
              wrapperClass: "col-span-6",
            })}

            {inputText({
              register,
              errors,
              label: "Pin code",
              name: "pincode",
              placeholder: "600 078",
              isRequired: false,
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
          </div>
          <div className="grid grid-cols-12 gap-x-3">
            {inputText({
              register,
              errors,
              label: "Mobile Number",
              name: "mobileNumber",
              placeholder: "044 12345678",
              isRequired: true,
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
            {inputText({
              register,
              errors,
              label: "Phone Number",
              name: "phoneNumber",
              placeholder: "044 12345678",
              isRequired: false,
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
            {inputEmail({
              register,
              errors,
              label: "Email ID",
              name: "email",
              placeholder: "hramkumar@gmail.com",
              isRequired: true,
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
          </div>
          {/* {view || edit ? (
            <div></div>
          ) : (
            <div>
              {inputPassword({
                register,
                errors,
                label: "Password",
                name: "password",
                minLength: true,
                isRequired: false,
                placeholder: "Password",
                readOnly: view,
              })}
              {inputPassword({
                register,
                errors,
                watch,
                name: "confirmPassword",
                label: "Confirm Password",
                validate: true,
                minLength: true,
                isRequired: false,
                placeholder: "Confirm Password",
                readOnly: view,
              })}
            </div>
          )} */}
          <div className="grid grid-cols-12 gap-x-3">
            {inputText({
              register,
              errors,
              label: "Pan Number",
              name: "panNumber",
              placeholder: "AAAAA1234Z",
              isRequired: false,
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
            {inputText({
              register,
              errors,
              label: "Plan Type",
              name: "planType",
              placeholder: "Plan Type",
              isRequired: false,
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
          </div>
          <div className="grid grid-cols-12 gap-x-3">
            {inputText({
              register,
              errors,
              label: "Bank Account Number",
              name: "bankAccountNumber",
              placeholder: "Bank Account Number",
              isRequired: false,
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
            {inputText({
              register,
              errors,
              label: "IFSC Code",
              name: "IFSCCode",
              placeholder: "IFSC Code",
              isRequired: false,
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
          </div>
          {inputText({
            register,
            errors,
            label: "Profile Picture",
            name: "profilePicture",
            placeholder: "Profile Picture",
            isRequired: false,
            readOnly: view,
          })}
          {/* {inputImgFile({
            register,
            errors,
            label: "Profile Picture",
            name: "profilePicture",
            placeholder: "Profile Picture",
            isRequired: false,
            onChange: handleFile,
            readOnly: view,
          })} */}
          {inputSwitch({
            register,
            errors,
            watch,
            name: "blocked",
            label: getDynamicLabel("blocked", watch("blocked")),
            isRequired: false,
            placeholder: "Blocked",
            readOnly: view,
          })}
          {view || edit ? (
            <>
              <div className="mb-3 p-3 border border-gray-400 rounded-md">
                <h4 className="font-semibold text-base mb-3">Permissions</h4>
                {inputMultipleCheckBoxGroups({
                  register,
                  errors,
                  label: "Permission",
                  name: "permission",
                  placeholder: "Permission",
                  data:
                    permissionData &&
                    permissionData
                      .filter((item) => item.show)
                      .map((item) => ({
                        name: `${item.name}`,
                        method: methodConversion(item.method),
                        _id: item._id,
                      })),
                  isRequired: false,
                  readOnly: view,
                  checkedValues: checkedPermissions,
                })}
              </div>

              <div className="mb-3 p-3 border border-gray-400 rounded-md">
                <h4 className="font-semibold text-base mb-3">Menus</h4>
                {inputMultipleCheckBox({
                  register,
                  errors,
                  label: "Menu",
                  name: "menu",
                  placeholder: "Menu",
                  data:
                    menuData &&
                    menuData.map((item) => ({
                      name: `${item.menu} - ${item.path}`,
                      _id: item._id,
                    })),
                  isRequired: false,
                })}
              </div>
            </>
          ) : (
            ""
          )}

          {view ? (
            ""
          ) : (
            <div className="flex mt-1 pt-3 border-t gap-3">
              <button
                type="submit"
                className="min-w-[120px] inline-flex items-center justify-center gap-1 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm px-3 py-0 h-8 text-center "
                disabled={isLoadingPost || isLoadingUpdate}
              >
                {isLoadingPost || isLoadingUpdate ? (
                  <span
                    className="animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full"
                    role="status"
                    aria-label="loading"
                  >
                    <span className="sr-only">Loading...</span>
                  </span>
                ) : (
                  <span>{edit ? "Update" : "Save"}</span>
                )}
              </button>
              <button
                type="button"
                className="px-3 py-0 h-8 inline-flex items-center gap-x-2 text-sm font-semibold rounded border border-gray-300 bg-white text-gray-800 hover:bg-gray-100  hover:shadow focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2 focus:ring-offset-white active:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
                onClick={() => {
                  setIsModalOpen(false);
                  formCleanHandler();
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default FormChannelPartners;
