import { Spinner, Message } from "..";
import { inputHidden, inputText, inputNumber } from "../../utils/dynamicForm";

const FormStates = ({
  edit,
  view,
  formCleanHandler,
  isLoading,
  register,
  isError,
  errors,
  isLoadingUpdate,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  error,
  setIsModalOpen,
  nextSequenceNumber,
}) => {
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <form onSubmit={handleSubmit(submitHandler)}>
          {inputHidden({
            register,
            errors,
            label: "",
            name: "sequenceNumber",
            placeholder: "Sequence Number",
            value: nextSequenceNumber > 0 ? nextSequenceNumber : 1,
            readOnly: true,
          })}
          {inputText({
            register,
            errors,
            label: "State Code",
            name: "stateID",
            placeholder: "State Code",
            value:
              "STA" +
              String(nextSequenceNumber > 0 ? nextSequenceNumber : 1).padStart(
                5,
                "0"
              ),
            readOnly: true,
          })}
          <div className="grid grid-cols-12 gap-x-3">
            {inputText({
              register,
              errors,
              label: "State Name",
              name: "stateName",
              placeholder: "State Name",
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
            {inputText({
              register,
              errors,
              label: "State Short Name",
              name: "stateShortName",
              placeholder: "State Short Name",
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
            {inputNumber({
              register,
              errors,
              label: "GST Code",
              name: "stateGSTCode",
              placeholder: "GST Code",
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
          </div>
          {view ? (
            ""
          ) : (
            <div className="flex mt-1 pt-3 border-t gap-3">
              <button
                type="submit"
                className="min-w-[120px] inline-flex items-center justify-center gap-1 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm px-3 py-0 h-8 text-center "
                disabled={isLoadingPost || isLoadingUpdate}
              >
                {isLoadingPost || isLoadingUpdate ? (
                  <span
                    className="animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full"
                    role="status"
                    aria-label="loading"
                  >
                    <span className="sr-only">Loading...</span>
                  </span>
                ) : (
                  <span>{edit ? "Update" : "Save"}</span>
                )}
              </button>
              <button
                type="button"
                className="px-3 py-1 inline-flex items-center gap-x-2 text-sm font-semibold rounded border border-gray-200 bg-white text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-50 active:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
                onClick={() => {
                  setIsModalOpen(false);
                  formCleanHandler();
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default FormStates;
