import { Search } from "..";
import Tooltip from "../../utils/Tooltip";
const ViewPermissions = ({
  data,
  editHandler,
  viewHandler,
  deleteHandler,
  isLoadingDelete,
  setQ,
  q,
  searchHandler,
  setIsModalOpen,
  setView,
}) => {
  const method = (classes, method, permission) => {
    return (
      permission.method === method && (
        <span
          className={`p-1 ${classes} border text-xs font-semibold rounded px-2`}
        >
          {permission.method}
        </span>
      )
    );
  };
  return (
    <>
      <div className="flex flex-wrap items-center justify-between mb-3">
        <h2 className="font-bold text-2xl text-gray-800 my-1">Permissions</h2>
        <div className="flex flex-wrap gap-3">
          <Search
            placeholder="Search by email"
            setQ={setQ}
            q={q}
            searchHandler={searchHandler}
          />
          <button
            className="inline-flex items-center gap-1 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm px-3 py-1 text-center"
            onClick={() => {
              setIsModalOpen(true);
              setView(false);
            }}
          >
            <span className="material-symbols-rounded">add</span>
            <span className="">New Permission</span>
          </button>
        </div>
      </div>
      <div className="relative overflow-x-auto rounded-lg border border-slate-300">
        <table className="w-full text-sm text-left text-gray-800 ">
          <thead className="text-xs text-slate-800 bg-slate-100 border-b-2 border-slate-300">
            <tr>
              <th className="px-2 py-3" style={{ width: "50px" }}>
                Sl.No
              </th>
              <th className="px-2 py-3">Name</th>
              <th className="px-2 py-3">Method</th>
              <th className="px-2 py-3">Route</th>
              <th className="px-2 py-3">Auth</th>
              <th className="px-2 py-3" style={{ width: "145px" }}>
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {data &&
              data.data.map((permission, index) => (
                <tr
                  className="border-b last:border-0 hover:bg-slate-100"
                  key={permission._id}
                >
                  <td className="p-2">{index + 1}</td>

                  <td className="p-2">{permission.name}</td>
                  <td className="p-2">
                    {method(
                      "bg-blue-100 text-blue-600 border-blue-300",
                      "GET",
                      permission
                    )}
                    {method(
                      "bg-green-100 text-green-600 border-green-300",
                      "POST",
                      permission
                    )}
                    {method(
                      "bg-orange-100 text-orange-600 border-orange-300",
                      "PUT",
                      permission
                    )}
                    {method(
                      "bg-red-100 text-red-600 border-red-300",
                      "DELETE",
                      permission
                    )}
                  </td>
                  <td className="p-2">{permission.route}</td>
                  <td className="p-2">
                    {permission.auth ? (
                      <Tooltip content="Auth Enabled" placement="bottom">
                        <span className="material-symbols-rounded filled text-green-600">
                          encrypted
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip content="No Authentication" placement="bottom">
                        <span className="material-symbols-rounded text-red-600">
                          dangerous
                        </span>
                      </Tooltip>
                    )}
                  </td>
                  <td className="px-2 py-1">
                    <div className="btn-group">
                      <Tooltip content="View" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => {
                            setIsModalOpen(true);
                            viewHandler(permission);
                          }}
                        >
                          <span className="material-symbols-rounded ">
                            visibility
                          </span>
                        </button>
                        </Tooltip>
                      <Tooltip content="Edit" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => {
                            setIsModalOpen(true);
                            editHandler(permission);
                          }}
                        >
                          <span className="material-symbols-rounded ">
                            edit
                          </span>
                        </button>
                      </Tooltip>
                      <Tooltip content="Delete" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-red-600 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => deleteHandler(permission._id)}
                          disabled={isLoadingDelete}
                        >
                          {isLoadingDelete ? (
                            <span
                              className="animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full"
                              role="status"
                              aria-label="loading"
                            >
                              <span className="sr-only">Loading...</span>
                            </span>
                          ) : (
                            <span className="material-symbols-rounded">
                              delete
                            </span>
                          )}
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ViewPermissions;
