import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import {
  inputHidden,
  inputCheckBox,
  inputSwitch,
  inputText,
  inputDate,
  InputAutoCompleteSelect,
  dynaimcInputSelectField,
  inputNumber,
  inputTextArea,
} from "../../utils/dynamicForm";
import useItemsHook from "../../api/items";
import useCustomersHook from "../../api/customers";
import useSalesHook from "../../api/sales";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
const FormSalesVoucher = () => {
  const location=useLocation();
  const [isPOSOpen, setIsPOSOpen] = useState(false);
  const [isAddedDescription, setIsAddedDescription] = useState(false);
  const [descriptionIndex, setDescriptionIndex] = useState(null);
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [summaryDetails, setSummaryDetails] = useState([]);
  const [totalSummary, setTotalSummary] = useState({
    taxableAmount: 0,
    cgst: 0,
    sgst: 0,
    igst: 0,
  });
  const [searchParams] = useSearchParams();
  
  const paramid = searchParams.get("paramid");
  const type = searchParams.get("type");
  const [edit, setEdit] = useState(type === "edit");
  const [view, setView] = useState(type === "view");
  const [count, setCount] = useState(0);
  const { getItems, postItem, updateItem, deleteItem } = useItemsHook({
    page,
    q,
  });
  const { getSales, postSale, updateSale, deleteSale } = useSalesHook(paramid?{
    page,
    id:paramid,
    q,
  }:{
    page,
    q,
  });
  const { data: salesData } = getSales;
  const { getCustomers } = useCustomersHook({
    page,
    q,
  });
  const [firstLoad,setFirstLoad]=useState(true)
  const { data: cusData } = getCustomers;
  const { data, isLoading, isError, error, refetch } = getItems;
  const navigate=useNavigate()
  
  const {
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
    isSuccess: isSuccessUpdate,
    mutateAsync: mutateAsyncUpdate,
  } = updateSale;
  const {
    isLoading: isLoadingPost,
    isError: isErrorPost,
    error: errorPost,
    isSuccess: isSuccessPost,
    mutateAsync: mutateAsyncPost,
  } = postSale;
  
  
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      products: [
        {
          productName: "",
          hsnCode: "",
          quantity: 0,
          unit: 0,
          price: 0,
          discount: 0,
          itemId:'',
          gstRate: 0,
          amount: 0,
          description: "",
          taxAmount: 0,
        }
      ],
      gstRate: "",
      packingCharge: 0,
      roundOff: 0,
      totalAmount: 0,
      grandTotal: 0,
      customerName: "",
      customerId: "",
      state: "",
      customerAddress: "",
      saleType: "Exclude Tax",
      gstNo: "",
      narration: "",
      InvoiceNo:"GST" + String("01").padStart(5, "0"),
    },
  });
  const watchedFields = watch();
  const totalAmount = watch("totalAmount");
  const grandTotal = watch("grandTotal");
  const customRound = (num) => {
    const decimalPart = num % 1; // Get the decimal part of the number
    if (decimalPart >= 0.5) {
      return Math.ceil(num); // Round up
    }
    return Math.floor(num); // Round down
  };
  useEffect(()=>{
    resetForm()
  },[location])
  const resetForm=()=>{
    reset( {
      products: [
        {
          productName: "",
          hsnCode: "",
          quantity: 0,
          unit: 0,
          price: 0,
          discount: 0,
          itemId:'',
          gstRate: 0,
          amount: 0,
          description: "",
          taxAmount: 0,
        }
      ],
      gstRate: "",
      packingCharge: 0,
      roundOff: 0,
      totalAmount: 0,
      grandTotal: 0,
      customerName: "",
      customerNamePOS: "",
      customerId: "",
      state: "",
      customerAddress: "",
      customerAddressPOS:"",
      customerContactPOS:"",
      saleType: "Exclude Tax",
      gstNo: "",
      gstNoPOS:"",
      narration: "",
      InvoiceNo:"GST" + String("01").padStart(5, "0"),
    })
    setSummaryDetails([])
    setTotalSummary({
      taxableAmount: 0,
      cgst: 0,
      sgst: 0,
      igst: 0,
    })
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-CA'); // "en-CA" gives yyyy-MM-dd format
    return formattedDate;
  };
  useEffect(() => {
    if (salesData?.data?.length === 1) {
      const selectedSalesData = salesData.data[0];
      const updatedData = {
        ...selectedSalesData,
        invoiceDate: formatDate(selectedSalesData.invoiceDate), // Format the date
      };
  
      reset(updatedData);
      setCount(count+1)
    }
  }, [salesData]);
  const createSummaryReport = () => {
    const isTamilState = watchedFields.state?.toLowerCase() == "tamilnadu";
    const summaryData = watchedFields.products
      .filter((x) => x.hsnCode)
      .reduce((acc, x) => {
        let existingEntry = acc.find((item) => item.gstRate == x.gstRate);

        if (!existingEntry) {
          existingEntry = {
            gstRate: x.gstRate,
            taxableAmount: 0,
            cgst: 0,
            sgst: 0,
            igst: 0,
          };
          acc.push(existingEntry);
        }
        const csgst = isTamilState ? x.taxAmount * 0.5 : 0;
        const igst = isTamilState ? 0 : x.taxAmount;
        existingEntry.taxableAmount += x.taxAmount;
        existingEntry.cgst += csgst;
        existingEntry.sgst += csgst;
        existingEntry.igst += igst;

        return acc;
      }, []);
    setSummaryDetails(summaryData);
    const totalSummary = summaryData.reduce((acc, x) => {
      return acc + x.taxableAmount;
    }, 0);
    setTotalSummary({
      taxableAmount: totalSummary,
      cgst: isTamilState ? totalSummary / 2 : 0,
      sgst: isTamilState ? totalSummary / 2 : 0,
      igst: isTamilState ? 0 : totalSummary,
    });
  };
  const [customerDropdownData, setCustomerDropdownData] = useState([]);
  useEffect(() => {
    setCustomerDropdownData(
      cusData?.data.map((x) => {
        return {
          _id: x._id,
          name: x.customerName,
        };
      })
    );
  }, [cusData]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });
  const submitHandler = (data) => {
    if(paramid){
      mutateAsyncUpdate(data).then((res)=>{
        navigate('/admin/auth/sales-purchase')
      })
      
    }
    else{
      mutateAsyncPost(data).then((res)=>{
        resetForm()
      })
    }
    
  };

  const togglePOS = () => {
    setIsPOSOpen(!isPOSOpen);
  };
  const handleSelectItem = (item, index) => {
    const selectedData = data.data.filter((x) => x._id == item);
    setValue(`products[${index}].itemId`,item)
    if (selectedData && selectedData.length > 0) {
      const product = selectedData[0];
      setValue(`products[${index}].hsnCode`, product.HSNCode || "");
      setValue(`products[${index}].productName`,product.name)
      setValue(
        `products[${index}].gstRate`,
        parseInt(product.GSTTaxRate.replace("%", "").trim()) || 0
      );
      setValue(`products[${index}].unit`, product.MRP || 0);
      setValue(`products[${index}].quantity`, 0);
      setValue(`products[${index}].price`, 0);
      setValue(
        `products[${index}].discount`,
        product.discount.replace("%", "").trim() || 0
      );
      setValue(`products[${index}].amount`, product.price || 0);
    } else {
      setValue(`products[${index}].hsnCode`, "");
      setValue(`products[${index}].gstRate`, 0);
      setValue(`products[${index}].unit`, 0);
      setValue(`products[${index}].price`, 0);
      setValue(`products[${index}].discount`, 0);
      setValue(`products[${index}].quantity`, 0);
      setValue(`products[${index}].amount`, 0);
    }
  };
  function CreateEachProductAmount(product, index) {
    
    const quantity = parseInt(product.quantity ? product.quantity : 0);
    const unit = parseFloat(product.unit ? product.unit : 0);
    const price = quantity * unit;
    const discount = parseFloat(product.discount ? product.discount : 0) / 100;
    const discountAmount = parseFloat(price * discount).toFixed(2);
    const priceWithDiscount = parseFloat((price - discountAmount).toFixed(2));
    const tax = ((product.gstRate ? product.gstRate : 0) / 100).toFixed(2);
    
    const taxAmount =
      watchedFields.saleType == "Exclude Tax"
        ? priceWithDiscount * tax
        : parseFloat(priceWithDiscount * parseFloat(tax)) /
          parseFloat(1 + parseFloat(tax));
    const amount =
      watchedFields.saleType == "Exclude Tax"
        ? priceWithDiscount + taxAmount
        : priceWithDiscount; 

    setValue(`products.${index}.taxAmount`, parseFloat(taxAmount.toFixed(2)));
    setValue(`products.${index}.price`, price);
    setValue(`products.${index}.amount`, amount);
  }

  useEffect(() => {
    if (
      watchedFields.products &&
      watchedFields.products.length > 0 &&
      watchedFields.products[0].hsnCode != ""
    ) {
      watchedFields.products.forEach((e, index) => {
        CreateEachProductAmount(e, index);
      });
      const totalAmount = watchedFields.products
        .reduce((acc, x) => {
          return acc + parseFloat(x.amount);
        }, 0.0)
        .toFixed(2);
      setValue(`totalAmount`, totalAmount);
      const roundOffValue = customRound(totalAmount);
      setValue("roundOff", roundOffValue);
      setValue(
        "grandTotal",
        roundOffValue + parseInt(watchedFields.packingCharge)
      );
      createSummaryReport();
    }
  }, [count]);

  const handleCustomerSelectItem = (e) => {
    
    const selectedCustomerDetails = cusData?.data?.filter(
      (x) => x._id === e
    );
    if (selectedCustomerDetails.length > 0) {
      setValue('customerId',e)
      setValue('customerName',selectedCustomerDetails[0].customerName)
      setValue(`gstNoPOS`, selectedCustomerDetails[0].GSTINNo);
      setValue(
        `customerAddressPOS`,
        `${selectedCustomerDetails[0].address1} , ${selectedCustomerDetails[0].address2} , ${selectedCustomerDetails[0].address3}`
      );
      setValue(`customerContactPOS`, selectedCustomerDetails[0].mobileNumber);
      setValue(`state`, selectedCustomerDetails[0].state);
    } else {
      setValue(`gstNoPOS`, "");
      setValue(`customerAddressPOS`, "");
      setValue(`customerContactPOS`, "");
      setValue(`state`, "");
    }
    setCount(count + 1);
  };
  return (
    <>
      <div className="grid grid-cols-12 gap-x-4 mb-3">
        <div className="col-span-6 grid grid-cols-12 gap-x-4">
          
          
          
                    <InputAutoCompleteSelect
                      register={register}
                      errors={errors}
                      id="customerListOption1"
                      isRequired={false}
                      data={customerDropdownData}
                      onChange={(e) => {
                        handleCustomerSelectItem(e);
                      }}
                      name={`customerName`}
                      label="Customer Name"
                      placeholder="Customer Name"
                      readOnly={view}
                      defaultValue={watch(`customerName`)}
                      wrapperClass="col-span-8"
                      inputClass="font-semibold"
                    />
                    
          {inputText({
            register,
            errors,
            label: "GST No",
            isRequired: false,
            name: `gstNoPOS`,
            placeholder: "GST No",
            readOnly: true,
            wrapperClass: "!m-0 col-span-4",
          })}
          {inputText({
            register,
            errors,
            label: "Address",
            isRequired: false,  
            name: `customerAddressPOS`,
            placeholder: "Address",
            readOnly: true,
            wrapperClass: "!m-0 col-span-8",
          })}
          {inputText({
            register,
            errors,
            label: "Contact Number",
            isRequired: false,
            name: `customerContactPOS`,
            placeholder: "Contact Number",
            readOnly: true,
            wrapperClass: "!m-0 col-span-4",
          })}
        </div>
        <div className="col-span-6 grid grid-cols-12 gap-x-4">
          {dynaimcInputSelectField({
            register,
            errors,
            label: "Sale Type",
            name: "saleType",
            value: "name",
            placeholder: "Sale Type",
            isRequired: false,
            data: [
              {
                name: "Include Tax",
                shortName: "include",
                _id: "Include Tax",
              },
              {
                name: "Exclude Tax",
                shortName: "exclude",
                _id: "Exclude Tax",
              },
            ],
            readOnly: view,
            wrapperClass: "col-span-4",
            onChange: (e) => {
              setValue("saleType", e);
              setCount(count + 1);
            },
          })}
          {inputText({
            register,
            errors,
            label: "Invoice No",
            name: "InvoiceNo",
            placeholder: "Invoice No",
            isRequired:true,
            readOnly: true,
            wrapperClass: "!m-0 col-span-4",
          })}
          {inputDate({
            register,
            errors,
            label: "Invoice Date",
            name: "invoiceDate",
            placeholder: "Invoice Date",
            readOnly: view,
            wrapperClass: "col-span-4",
          })}
          <div className="col-span-4 mt-2">
            {/* P.O.S Section */}
            <button
              type="button"
              onClick={togglePOS}
              className="min-w-[120px] text-blue-600 border border-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm p-1 h-8 text-center"
            >
              {isPOSOpen ? "Close P.O.S" : "Open P.O.S"}
            </button>
          </div>
        </div>
      </div>

      <Dialog
        open={isPOSOpen}
        onClose={() => {
          //setIsPOSOpen(false);
        }}
        transition
        className="realtive z-[1000] transition duration-100 ease-linear data-[closed]:opacity-0"
      >
        <div className="fixed z-[1000] inset-0 flex w-screen justify-center items-center p-2">
          <DialogPanel className="w-full max-w-[800px] max-h-[calc(100vh_-_1rem)] overflow-auto flex flex-col rounded-xl shadow-sm bg-white">
            <DialogTitle
              className="flex justify-between items-center p-3"
              as="div"
            >
              <h3 className="text-2xl font-bold">P.O.S Details</h3>

              <button
                type="button"
                className="inline-flex text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-600 focus-visible:ring-4 transition duration-150 ease-linear p-1"
                aria-label="Close"
                onClick={() => {
                  setIsPOSOpen(false);
                }}
              >
                <span className="material-symbols-rounded">close</span>
              </button>
            </DialogTitle>
            <div className="bg-gray-50 p-4 rounded border mb-6">
              <div className="grid grid-cols-2 gap-x-4">
                <div>
                  <label className="block text-gray-600 mb-2">
                    Customer Name
                  </label>
                  <input
                    type="text"
                    {...register("customerNamePOS")}
                    placeholder="Customer Name"
                    className="w-full p-2 border rounded"
                    readOnly={view}
                  />
                </div>
                <div>
                  <label className="block text-gray-600 mb-2">GST No</label>
                  <input
                    type="text"
                    {...register("gstNoPOS")}
                    placeholder="GST No"
                    className="w-full p-2 border rounded"
                    readOnly={view}
                  />
                </div>
                <div>
                  <label className="block text-gray-600 mb-2">Address</label>
                  <input
                    type="text"
                    {...register("customerAddressPOS")}
                    placeholder="Address"
                    className="w-full p-2 border rounded"
                    readOnly={view}
                  />
                </div>
                <div>
                  <label className="block text-gray-600 mb-2">
                    Contact Number
                  </label>
                  <input
                    type="text"
                    {...register("customerContactPOS")}
                    placeholder="Contact Number"
                    className="w-full p-2 border rounded"
                    readOnly={view}
                  />
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
        <DialogBackdrop
          className="fixed z-[999] inset-0 bg-black/50"
          onClick={(e) => e.stopPropagation()}
        />
      </Dialog>
      <Dialog
        open={isAddedDescription}
        onClose={() => {
          setIsAddedDescription(false);
          setDescriptionIndex(null);
        }}
        transition
        className="realtive z-[1000] transition duration-100 ease-linear data-[closed]:opacity-0"
      >
        <div className="fixed z-[1000] inset-0 flex w-screen justify-center items-center p-2">
          <DialogPanel className="w-full max-w-[800px] max-h-[calc(100vh_-_1rem)] overflow-auto flex flex-col rounded-xl shadow-sm bg-white">
            <DialogTitle
              className="flex justify-between items-center p-3"
              as="div"
            >
              <h3 className="text-2xl font-bold">Description</h3>

              <button
                type="button"
                className="inline-flex text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-600 focus-visible:ring-4 transition duration-150 ease-linear p-1"
                aria-label="Close"
                onClick={() => {
                  setIsAddedDescription(false);
                  setDescriptionIndex(null);
                }}
              >
                <span className="material-symbols-rounded">close</span>
              </button>
            </DialogTitle>
            <div className="p-4 mb-3">
              <div className="grid grid-cols-2 gap-x-4">
                {inputTextArea({
                  wrapperClass: "!m-0 col-span-2",
                  register,
                  errors,
                  placeholder: "Write here...",
                  name: `products.${descriptionIndex}.description`,
                  readOnly: view,
                  isRequired: false,
                })}
              </div>
            </div>
          </DialogPanel>
        </div>
        <DialogBackdrop
          className="fixed z-[999] inset-0 bg-black/50"
          onClick={(e) => e.stopPropagation()}
        />
      </Dialog>
      <div className="flex-1 mb-3 overflow-y-auto">
        {/* Product Table */}
        <h3 className="text-base font-bold text-gray-800 mb-2">Products</h3>

        <div className="relative overflow-x-auto rounded-lg border border-slate-300">
          <table className="w-full text-sm text-left text-gray-800 ">
            <thead className="text-xs text-slate-800 bg-slate-100 border-b-2 border-slate-300">
              <tr>
                <th className="px-4 py-2">#</th>
                <th className="border-l border-gray-300 px-2 py-3">
                  Product Name
                </th>
                <th className="border-l border-gray-300 px-2 py-3">HSN Code</th>
                <th className="border-l border-gray-300 px-2 py-3">Quantity</th>
                <th className="border-l border-gray-300 px-2 py-3">Unit</th>
                <th className="border-l border-gray-300 px-2 py-3">Price</th>
                <th className="border-l border-gray-300 px-2 py-3">
                  Discount %
                </th>
                <th className="border-l border-gray-300 px-2 py-3">
                  GST Rate %
                </th>
                <th className="border-l border-gray-300 px-2 py-3">Amount</th>
                <th
                  className="border-l border-gray-300 px-2 py-3"
                  style={{ width: "110px" }}
                >
                  Desc
                </th>
                <th
                  className="border-l border-gray-300 px-2 py-3"
                  style={{ width: "50px" }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {fields.map((item, index) => (
                <tr
                  key={item.id}
                  className="border-b last:border-0 hover:bg-slate-100"
                >
                  <td className="border-b  border-gray-300 text-center">
                    {index + 1}
                  </td>
                  <td className="border-b  border-l border-gray-300">
                    {/* <input
                        type="text"
                        {...register(`products.${index}.productName`)}
                        placeholder="Product Name"
                        className="w-full p-2 border rounded"
                      /> */}
                    {/* {InputAutoCompleteSelect({
                        register,
                        errors,
                        label: "Product Name",
                        data:data?.data,
                        name: "`products.${index}.productName`",
                        placeholder: "Product Name",
                        readOnly: false,
                      })} */}

                    <InputAutoCompleteSelect
                      register={register}
                      errors={errors}
                      id="itemListOption"
                      isRequired={false}
                      data={data?.data}
                      onChange={(e) => {
                        handleSelectItem(e, index);
                      }}
                      name={`products.${index}.productName`}
                      placeholder="Product Name"
                      readOnly={view}
                      defaultValue={watch(`products.${index}.productName`)}
                      wrapperClass="!m-0"
                      inputClass="rounded-none ring-transparent"
                    />
                    
                  </td>
                  <td className="border-b  border-l border-gray-300">
                    {/* <input
                        type="text"
                        {...register(`products.${index}.hsnCode`)}
                        placeholder="HSN Code"
                        className="w-full p-2 border rounded"
                      /> */}
                    {inputText({
                      register,
                      errors,
                      isRequired: false,
                      name: `products.${index}.hsnCode`,
                      placeholder: "HSN Code",
                      readOnly: true,
                      wrapperClass: "!m-0",
                      inputClass: "rounded-none ring-transparent",
                    })}
                  </td>
                  <td className="border-b  border-l border-gray-300">
                    {inputNumber({
                      register,
                      errors,
                      onChange: (e) => {
                        // const formdata = getValues();
                        // const discount = parseInt(formdata.products[index].discount);
                        // calculateAmount(e?e:0, discount?discount:0,index,formdata.saleType);
                        setValue(`products.${index}.quantity`, e);
                        setCount(count + 1);
                      },
                      name: `products.${index}.quantity`,
                      placeholder: "Quantity",
                      readOnly: view,
                      wrapperClass: "!m-0",
                      inputClass: "rounded-none ring-transparent",
                    })}
                  </td>
                  <td className="border-b  border-l border-gray-300">
                    {inputText({
                      register,
                      errors,
                      name: `products.${index}.unit`,
                      placeholder: "Unit",
                      readOnly: true,
                      isRequired: false,
                      wrapperClass: "!m-0",
                      inputClass: "rounded-none ring-transparent",
                    })}
                  </td>
                  <td className="border-b  border-l border-gray-300">
                    {inputNumber({
                      readOnly: true,
                      register,
                      errors,
                      name: `products.${index}.price`,
                      onChange: (e) => {
                        setValue(`products.${index}.price`, e);
                        //setCount(count+1)
                      },
                      placeholder: "Price",
                      wrapperClass: "!m-0",
                      inputClass: "rounded-none ring-transparent",
                    })}
                  </td>
                  <td className="border-b  border-l border-gray-300">
                    {inputNumber({
                      register,
                      errors,
                      name: `products.${index}.discount`,
                      onChange: (e) => {
                        // const formdata = getValues();
                        // const quantity = parseInt(formdata.products[index].quantity,formdata.saleType);
                        // calculateAmount(quantity?quantity:0, e?e:0,index,formdata.saleType);
                        setValue(`products.${index}.discount`, e);
                        setCount(count + 1);
                      },
                      placeholder: "Discount %",
                      readOnly: view,
                      wrapperClass: "!m-0",
                      inputClass: "rounded-none ring-transparent",
                    })}
                  </td>
                  <td className="border-b  border-l border-gray-300">
                    {inputNumber({
                      register,
                      errors,
                      name: `products.${index}.gstRate`,
                      readOnly: true,
                      wrapperClass: "!m-0",
                      inputClass: "rounded-none ring-transparent",
                    })}
                  </td>
                  <td className="border-b  border-l border-gray-300">
                    {inputNumber({
                      register,
                      errors,
                      placeholder: "Amount",
                      name: `products.${index}.amount`,
                      readOnly: true,
                      wrapperClass: "!m-0",
                      inputClass: "rounded-none ring-transparent",
                    })}
                  </td>

                  <td className="border-b  border-l border-gray-300 text-center">
                    <button
                      type="button"
                      onClick={() => {
                        setIsAddedDescription(true);
                        setDescriptionIndex(index);
                      }}
                      className="bg-white border border-blue-600 text-blue-600 px-2 py-1 rounded"
                    >
                      Add Desc.
                    </button>
                  </td>
                  <td className="border-b  border-l border-gray-300 text-center">
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => {
                          remove(index);
                          setCount(count + 1);
                        }}
                        className="text-red-500 border border-transparent hover:bg-white hover:border-red-600 px-1 py-1 rounded-full"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="20"
                          viewBox="0 -960 960 960"
                          width="20"
                          fill="currentColor"
                        >
                          <path d="M292.31-140q-29.92 0-51.12-21.19Q220-182.39 220-212.31V-720h-40v-60h180v-35.38h240V-780h180v60h-40v507.69Q740-182 719-161q-21 21-51.31 21H292.31ZM680-720H280v507.69q0 5.39 3.46 8.85t8.85 3.46h375.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46V-720ZM376.16-280h59.99v-360h-59.99v360Zm147.69 0h59.99v-360h-59.99v360ZM280-720v520-520Z" />
                        </svg>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button
          type="button"
          onClick={() => {
            append({});
            setCount(count + 1);
          }}
          className="bg-green-600 text-white font-semibold px-3 py-1 rounded mt-2"
        >
          Add Product
        </button>
      </div>
      <div className=" bg-white rounded-t-xl border pt-2 px-3 -mx-3 -mb-3">
        {/* Summary Section */}
        <h3 className="text-base font-bold text-gray-800 mb-2">Summary</h3>
        <div className="grid grid-cols-12 gap-x-4">
          <div className="col-span-6 relative overflow-x-auto rounded-lg border border-slate-300">
            <table className="w-full text-sm text-left text-gray-800 ">
              <thead className="text-xs text-slate-800 bg-slate-100 border-b-2 border-slate-300">
                <tr>
                  <th className="p-2">GST Rate %</th>
                  <th className="p-2 border-l border-gray-300">
                    Taxable Amount
                  </th>
                  <th className="p-2 border-l border-gray-300">CGST</th>
                  <th className="p-2 border-l border-gray-300">SGST</th>
                  <th className="p-2 border-l border-gray-300">IGST</th>
                </tr>
              </thead>
              <tbody>
                {summaryDetails.map((x) => (
                  <tr className="border-b last:border-0 hover:bg-slate-100">
                    <td className="px-2 py-1">{x.gstRate}</td>
                    <td className="px-2 py-1 border-l border-gray-300">
                      {x.taxableAmount}
                    </td>
                    <td className="px-2 py-1 border-l border-gray-300">
                      {x.cgst}
                    </td>
                    <td className="px-2 py-1 border-l border-gray-300">
                      {x.sgst}
                    </td>
                    <td className="px-2 py-1 border-l border-gray-300">
                      {x.igst}
                    </td>
                  </tr>
                ))}
                <tr className="border-b last:border-0 hover:bg-slate-100">
                  <th className="px-2 py-1">Total</th>
                  <td className="px-2 py-1 border-l border-gray-300">
                    {totalSummary?.taxableAmount}
                  </td>
                  <td className="px-2 py-1 border-l border-gray-300">
                    {totalSummary?.cgst}
                  </td>
                  <td className="px-2 py-1 border-l border-gray-300">
                    {totalSummary?.sgst}
                  </td>
                  <td className="px-2 py-1 border-l border-gray-300">
                    {totalSummary.igst}
                  </td>
                </tr>
                <tr className="border-b last:border-0 hover:bg-slate-100">
                  <th className="px-2 py-1">Narration</th>
                  <td className="p-1 border-l border-gray-300" colSpan="4">
                    {inputTextArea({
                      register,
                      errors,
                      name: "narration",
                      placeholder: "Write here...",
                      readOnly: view,
                      isRequired: false,
                      wrapperClass: "!m-0",
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-span-6 ">
            <div className="col-span-6 relative overflow-x-auto rounded-lg border border-slate-300">
              <table className="w-full text-sm text-left text-gray-800 ">
                <tbody>
                  <tr>
                    <th className="p-1 text-right">Total Amount</th>
                    <td className="p-1">
                      {inputNumber({
                        register,
                        errors,
                        placeholder: "Total Amount",
                        name: `totalAmount`,
                        readOnly: true,
                        wrapperClass: "!m-0",
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th className="p-1 text-right">Package Amount</th>
                    <td className="p-1">
                      {inputNumber({
                        register,
                        onChange: (e) => {
                          setValue("packingCharge", e);
                          setCount(count + 1);
                        },
                        errors,
                        placeholder: "Package Amount",
                        name: `packingCharge`,
                        wrapperClass: "!m-0",
                        readOnly:view
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th className="p-1 text-right">Round Off</th>
                    <td className="p-1">
                      {inputNumber({
                        readOnly: true,
                        register,
                        errors,
                        placeholder: "Round Off",
                        name: `roundOff`,
                        wrapperClass: "!m-0",
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th className="p-1 text-right">Grass Amount</th>
                    <td className="p-1">
                      {inputNumber({
                        register,
                        errors,
                        placeholder: "Grand Total",
                        name: `grandTotal`,
                        readOnly: true,
                        wrapperClass: "!m-0",
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex border-t pt-2 pb-4 mt-2  gap-x-3">
          {
            !view &&
            <button
            type="submit"
            className="min-w-[120px] inline-flex items-center justify-center gap-1 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm p-1 h-8 text-center "
            onClick={handleSubmit(submitHandler)}
          >
            {
             edit?'Update':'Save'
            }
          </button>
          }
          
          <button
            type="button"
            className="px-3 py-0 h-8 inline-flex items-center gap-x-2 text-sm font-semibold rounded border border-gray-300 bg-white text-gray-800 hover:bg-gray-100  hover:shadow focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2 focus:ring-offset-white active:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
            onClick={() => navigate('/admin/auth/sales-purchase')}
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default FormSalesVoucher;
