import moment from "moment";
import { Search } from "..";
import Tooltip from "../../utils/Tooltip";

const ViewUsers = ({
  data,
  editHandler,
  viewHandler,
  deleteHandler,
  isLoadingDelete,
  setIsModalOpen,
  setQ,
  q,
  searchHandler,
  setImageFile,
}) => {
  // console.log("data", data);
  return (
    <>
      <div className="flex flex-wrap items-center justify-between mb-3">
        <h2 className="font-bold text-2xl text-gray-800 my-1">Users</h2>
        <div className="flex flex-wrap gap-3">
          <Search
            placeholder="Search by email"
            setQ={setQ}
            q={q}
            searchHandler={searchHandler}
          />
          <button
            className="inline-flex items-center gap-1 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm px-3 py-1 text-center"
            onClick={() => {setIsModalOpen(true); setImageFile();}}
          >
            <span className="material-symbols-rounded">add</span>
            <span className="">New User</span>
          </button>
        </div>
      </div>
      <div className="relative overflow-x-auto rounded-lg border border-slate-300">
        <table className="table-fixed w-full text-sm text-left text-gray-800 ">
          <thead className="text-xs text-slate-800 bg-slate-100 border-b-2 border-slate-300">
            <tr>
              <th className="px-2 py-3" style={{ width: "50px" }}>
                Sl.No
              </th>
              <th className="px-2 py-3" style={{ width: "120px" }}>
                User Code
              </th>
              <th className="px-2 py-3">Name & Email</th>
              <th className="px-2 py-3">Address</th>
              <th className="px-2 py-3">City</th>
              <th className="px-2 py-3">State</th>
              <th className="px-2 py-3" style={{ width: "130px" }}>
                Mobile
              </th>
              <th className="px-2 py-3" style={{ width: "190px" }}>
                Joined Date
              </th>
              <th className="px-2 py-3" style={{ width: "80px" }}>
                Approved
              </th>
              <th className="px-2 py-3" style={{ width: "90px" }}>
                Status
              </th>
              <th className="px-2 py-3" style={{ width: "145px" }}>
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {data &&
              data.data.map((user, index) => (
                <tr
                  key={user._id}
                  className={`border-b last:border-0 hover:bg-slate-100 ${
                    !user.blocked
                      ? "hover:shadow-[inset_3px_0_0_0_theme(colors.red.600)]"
                      : ""
                  }`}
                >
                  <td className="p-2">{index + 1}</td>

                  <td className="p-2 truncate">{user.userID}</td>
                  <td className="p-2 truncate">
                    <div className="flex flex-col">
                      <span className="font-semibold">
                        {user.firstName} {user.lastName}
                      </span>
                      <a
                        href={`mailto:${user.email}`}
                        className="text-xs truncate"
                      >
                        {user.email}
                      </a>
                    </div>
                  </td>
                  <td className="p-2 truncate">{user.profile?.address1}</td>
                  <td className="p-2 truncate">{user.profile?.city}</td>
                  <td className="p-2 truncate">{user.profile?.state}</td>
                  <td className="p-2 truncate">{user.profile?.mobile}</td>
                  <td className="p-2 truncate">
                    {moment(user.createdAt).format("lll")}
                  </td>
                  <td className="p-2 truncate">
                    {user.confirmed ? (
                      <Tooltip content="Verified" placement="bottom">
                        <span className="material-symbols-rounded text-green-600 cursor-default">
                          verified_user
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip content="Pending" placement="bottom">
                        <span className="material-symbols-rounded text-red-600 cursor-default">
                          pending
                        </span>
                      </Tooltip>
                    )}
                  </td>
                  <td className="p-2">
                    {user.blocked ? (
                      <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                        Active
                      </span>
                    ) : (
                      <span className="text-xs bg-red-100 border border-red-300 text-red-700 rounded-full px-2 py-1">
                        Inactive
                      </span>
                    )}
                  </td>
                  <td className="px-2 py-1">
                    <div className="flex flex-wrap">
                      <Tooltip content="View" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2 transition duration-100 ease-linear"
                          onClick={() => {
                            setIsModalOpen(true);
                            viewHandler(user);
                          }}
                        >
                          <span className="material-symbols-rounded ">
                            visibility
                          </span>
                        </button>
                      </Tooltip>
                      <Tooltip content="Edit" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2 transition duration-100 ease-linear"
                          onMouseOver={() => {}}
                          onClick={() => {
                            setIsModalOpen(true);
                            editHandler(user);
                          }}
                        >
                          <span className="material-symbols-rounded ">
                            edit
                          </span>
                        </button>
                      </Tooltip>
                      <Tooltip content="Delete" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-red-600 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => deleteHandler(user._id)}
                          disabled={isLoadingDelete}
                        >
                          {isLoadingDelete ? (
                            <span
                              className="animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full"
                              role="status"
                              aria-label="loading"
                            >
                              <span className="sr-only">Loading...</span>
                            </span>
                          ) : (
                            <span className="material-symbols-rounded">
                              delete
                            </span>
                          )}
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                  {/* <td className="p-2 truncate">
                    <div className="flex flex-wrap">
                      <button
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2 transition duration-100 ease-linear"
                          onClick={() => {
                            setIsModalOpen(true);
                            editHandler(user);
                          }}
                        >                        
                          <span className="material-symbols-rounded ">shield_person</span>
                        </button>
                    </div>
                  </td> */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ViewUsers;
