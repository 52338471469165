import moment from "moment";
import { Search } from "..";
import Tooltip from "../../utils/Tooltip";

const ViewCompany = ({
  data,
  viewHandler,
  editHandler,
  deleteHandler,
  isLoadingDelete,
  setIsModalOpen,
  setImageFile,
  setQ,
  q,
  searchHandler,
  viewCompanyHandler,
}) => {
  return (
    <>
      <div className="flex flex-wrap items-center justify-between mb-3">
        <h2 className="font-bold text-2xl text-gray-800 my-1">Companies</h2>
        <div className="flex flex-wrap gap-3">
          <Search
            placeholder="Search by email"
            setQ={setQ}
            q={q}
            searchHandler={searchHandler}
          />
          <button
            className="inline-flex items-center gap-1 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm px-3 py-1 text-center"
            onClick={() => {
              setIsModalOpen(true);
              setImageFile();
            }}
          >
            <span className="material-symbols-rounded">add</span>
            <span className="">New Company</span>
          </button>
        </div>
      </div>
      <div className="relative overflow-x-auto rounded-lg border border-slate-300">
        <table className="table-fixed w-full text-sm text-left text-gray-800 ">
          <thead className="text-xs text-slate-800 bg-slate-100 border-b-2 border-slate-300">
            <tr>
              <th className="px-2 py-3" style={{ width: "50px" }}>
                Sl.No
              </th>
              <th className="px-2 py-3" style={{ width: "150px" }}>
                Company Code
              </th>
              <th className="px-2 py-3" style={{ width: "170px" }}>
                Joined Date
              </th>
              <th className="px-2 py-3" style={{ width: "150px" }}>
                Introduction ID
              </th>
              <th className="px-2 py-3" style={{ width: "150px" }}>
                Company ID
              </th>
              <th className="px-2 py-3" style={{ width: "300px" }}>
                Company Name
              </th>
              <th className="px-2 py-3" style={{ width: "100px" }}>
                Address 1
              </th>
              <th className="px-2 py-3" style={{ width: "100px" }}>
                Address 2
              </th>
              <th className="px-2 py-3" style={{ width: "100px" }}>
                Address 3
              </th>
              <th className="px-2 py-3" style={{ width: "100px" }}>
                City
              </th>
              <th className="px-2 py-3" style={{ width: "100px" }}>
                State
              </th>
              <th className="px-2 py-3" style={{ width: "150px" }}>
                Mobile No.
              </th>
              <th className="px-2 py-3" style={{ width: "200px" }}>
                Email
              </th>
              <th className="px-2 py-3" style={{ width: "100px" }}>
                Service Type
              </th>
              <th className="px-2 py-3" style={{ width: "80px" }}>
                Status
              </th>
              <th className="px-2 py-3" style={{ width: "180px" }}>
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {data &&
              data.data.map((company, index) => (
                <tr
                  key={company._id}
                  className={`border-b last:border-0 hover:bg-slate-100 ${
                    !company.blocked
                      ? "hover:shadow-[inset_3px_0_0_0_theme(colors.red.600)]"
                      : ""
                  }`}
                >
                  <td className="p-2">{index + 1}</td>

                  <td className="p-2">{company.companySerialNo}</td>
                  <td className="p-2">
                    {moment(company.createdAt).format("lll")}
                  </td>
                  <td className="p-2">{company.introductionID}</td>
                  <td className="p-2">{company.companyID}</td>
                  <td className="p-2">{company.companyName}</td>
                  <td className="p-2 truncate">{company.address1}</td>
                  <td className="p-2 truncate">{company.address2}</td>
                  <td className="p-2 truncate">{company.address3}</td>
                  <td className="p-2 truncate">{company.city}</td>
                  <td className="p-2 truncate">{company.state}</td>
                  <td className="p-2">{company.mobile}</td>
                  <td className="p-2">{company.email}</td>
                  <td className="p-2">{company.typeofService}</td>
                  {/* <td className="p-2">
                    {company.confirmed ? (
                        <span className="material-symbols-rounded text-green-600 cursor-default">
                          verified_user
                        </span>
                    ) : (
                      <span className="material-symbols-rounded text-red-600 cursor-default">
                          pending
                        </span>
                    )}
                  </td> */}
                  <td className="p-2">
                    {company.blocked ? (
                      <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                        Active
                      </span>
                    ) : (
                      <span className="text-xs bg-red-100 border border-red-300 text-red-700 rounded-full px-2 py-1">
                        Inactive
                      </span>
                    )}
                  </td>
                  <td className="px-2 py-1">
                    <div className="flex flex-wrap">
                      <Tooltip content="View" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => {
                            setIsModalOpen(true);
                            viewHandler(company);
                          }}
                        >
                          <span className="material-symbols-rounded ">
                            visibility
                          </span>
                        </button>
                      </Tooltip>
                      <Tooltip content="Edit" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => {
                            setIsModalOpen(true);
                            editHandler(company);
                          }}
                        >
                          <span className="material-symbols-rounded ">
                            edit
                          </span>
                        </button>
                      </Tooltip>
                      <Tooltip content="View as Company" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => {
                            viewCompanyHandler(company);
                          }}
                        >
                          <span className="material-symbols-rounded ">
                            source_environment
                          </span>
                        </button>
                      </Tooltip>
                      {/* <button onClick={()=>{viewCompanyHandler(company)}}>
                        <img
                          src="https://cdn-icons-png.freepik.com/256/3328/3328224.png?ga=GA1.1.42440995.1721449048&semt=ais_hybrid"
                          alt="Company Icon"
                          style={{ width: '24px', height: '24px' }}
                        />
                      </button> */}
                      <Tooltip content="Delete" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-red-600 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => deleteHandler(company._id)}
                          disabled={isLoadingDelete}
                        >
                          {isLoadingDelete ? (
                            <span
                              className="animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full"
                              role="status"
                              aria-label="loading"
                            >
                              <span className="sr-only">Loading...</span>
                            </span>
                          ) : (
                            <span className="material-symbols-rounded">
                              delete
                            </span>
                          )}
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ViewCompany;
