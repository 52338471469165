import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import {
  inputHidden,
  inputCheckBox,
  inputSwitch,
  inputText,
  inputTextArea,
  inputDate,
  InputAutoCompleteSelect,
  dynaimcInputSelectField,
  inputNumber,
} from "../../../utils/dynamicForm";
import useItemsHook from "../../../api/items";
import useCustomersHook from "../../../api/customers";
import useSalesHook from "../../../api/sales";
const ReceiptVoucher = () => {
  const [isAccountOpen, setIsAccountSOpen] = useState(false);
  const [isBB, setIsBB] = useState(false);
  const [bbIndex, setBBIndex] = useState(null);
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [selectedCustomerData,setSelectedCustomerData]=useState(null)
  const [accountIndex,setAccountIndex]=useState(null)
  const [count, setCount] = useState(0);
  const [selectedCashType,setSelectedCashType]=useState(null)
  const [selectedCustomerSalesData,setSelectedCustomerSalesData]=useState([])
  const [modiedSalesData,setModifiedSalesData]=useState([])
  const { getSales,updateSale } = useSalesHook({
    page,
    q
  });
  const { data: salesData } = getSales;
  const { getCustomers } = useCustomersHook({
    page,
    q,
  });
  useEffect(()=>{
    if(salesData?.data?.length>0){
      setModifiedSalesData(salesData.data)
    }
  },[salesData])
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      products: [
        {
          accountName: "",
          amountType: "",
          discount: 0,
          total: 0,
          amount: 0,
          refNo:"",
          chequeNo:"",
          chequeDate:"",
          purchesedAmount:[],
          bankName:"",
          customerId:""
        },
      ],
      modeOfPayment: "Cash/Bank Account",
      receiptNo: 1,
      invoiceDate: "",
      narration: "",
      grandTotal: 0
    },
  });
  const [isChecked, setIsChecked] = useState(false);
const [selectedCustomerId,setSelectedCustomerId]=useState(null)
  const handleToggle = (salesId) => {
    console.log(salesId)
    const accountDetail=watchedFields.products.filter(x=> x.customerId===selectedCustomerId)[0]
    console.log(accountDetail.total)
    console.log(accountDetail.purchesedAmount)
    // const updatedSalesData=modiedSalesData.map((x)=>{
    //   if(x._id===salesId){
    //     return {
    //       ...x,payedAmount:totalAmount
    //     }
    //   }
    //   return x
    // })
    // setModifiedSalesData(updatedSalesData)
    setValue(`products.${bbIndex}.purchesedAmount`, )
    //console.log(updatedSalesData)
    setIsChecked((prev) => !prev);
  };
  const watchedFields = watch();
  const { data: cusData } = getCustomers;
  const [customerDropdownData, setCustomerDropdownData] = useState([]);
  useEffect(() => {
    setCustomerDropdownData(
      cusData?.data.map((x) => {
        return {
          _id: x._id,
          name: x.customerName,
        };
      })
    );
  }, [cusData]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });
  const submitHandler = (data) => {
    console.log("Form Data:", data);
    alert("Sales Voucher Submitted Successfully");
    reset();
  };

  const togglePOS = () => {
      window.print();
  };
  const handleSelectItem = (item, index) => {
    console.log(item)
    const customerDetail=customerDropdownData.filter(x=> x._id===item)
    if(customerDetail?.length>0){
      console.log(customerDetail)
      setSelectedCustomerData(customerDetail[0])
      setIsAccountSOpen(true);
      setAccountIndex(index)
      setValue(`products[${index}].cashType`, "UPI");
      setValue(`products[${index}].customerId`, item);
      setSelectedCashType('UPI')
    }
    
  };
  const handleAmountChange=(index)=>{
    const amountNum=watchedFields.products[index].amount
    const discountNum=watchedFields.products[index].discount
    const discountWithPercentage= (parseInt(amountNum) - (parseInt(amountNum) * (parseInt(discountNum)/100)))
    setValue(`products[${index}].total`, discountWithPercentage);
    const grandTotal = watchedFields.products.reduce((acc,x)=>{
      return acc+parseFloat(x.total)
    },0)
    setValue(`grandTotal`, grandTotal);
  }
  const bbFunction=(index)=>{
    setIsBB(true);
    setBBIndex(index);
    if (watchedFields?.products[index]?.customerId) {
      const cusId=watchedFields?.products[index]?.customerId
      console.log(salesData.data.filter(x=>x.customerId===cusId))
      setSelectedCustomerId(cusId)
      setSelectedCustomerSalesData(salesData.data.filter(x=>x.customerId===cusId))
    }
  

  }
  
  return (
    <div className="flex flex-col max-w-[1660px] min-h-[calc(100vh_-_theme(spacing.6))] mx-auto">
      <h1 className="text-2xl font-bold mb-4">Receipts</h1>
      <div className="grid grid-cols-12 gap-x-4 mb-3">
        <div className="col-span-4 grid grid-cols-12 gap-x-4">
          {inputText({
            register,
            errors,
            label: "Mode of Payment",
            isRequired: false,
            name: "modeOfPayment",
            placeholder: "Mode of Payment",
            readOnly: true,
            wrapperClass: "col-span-12",
          })}
          <div className="col-span-12 mb-2">
            <button
              type="button"
              onClick={togglePOS}
              className="min-w-[120px] col-span-4 text-blue-600 border border-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm p-1 h-8 text-center "
            >
              Print Balance
            </button>
          </div>
        </div>

        <div className="col-span-4 grid grid-cols-12 gap-x-4">
          {inputText({
            register,
            errors,
            label: "Receipt No",
            isRequired: false,
            name: `receiptNo`,
            placeholder: "Receipt Number",
            readOnly: true,
            wrapperClass: "col-span-12",
          })}
          {inputDate({
            register,
            errors,
            label: "Invoice Date",
            name: "invoiceDate",
            placeholder: "Invoice Date",
            readOnly: false,
            wrapperClass: "col-span-12",
          })}
        </div>
      </div>

      <Dialog
        open={isAccountOpen}
        onClose={() => {
          setIsAccountSOpen(false);
          setAccountIndex(null)
        }}
        transition
        className="realtive z-[1000] transition duration-100 ease-linear data-[closed]:opacity-0"
      >
        <div className="fixed z-[1000] inset-0 flex w-screen justify-center items-center p-2">
          <DialogPanel className="w-full max-w-[800px] max-h-[calc(100vh_-_1rem)] min-h-40 overflow-auto flex flex-col rounded-xl shadow-sm bg-white">
            <DialogTitle
              className="flex justify-between items-center p-3"
              as="div"
            >
              <h3 className="text-2xl font-bold">Bank Details</h3>

              <button
                type="button"
                className="inline-flex text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-600 focus-visible:ring-4 transition duration-150 ease-linear p-1"
                aria-label="Close"
                onClick={() => {
                  setIsAccountSOpen(false);
                }}
              >
                <span className="material-symbols-rounded">close</span>
              </button>
            </DialogTitle>
            <div className="p-4 mb-6">
              <div className="grid grid-cols-12 gap-x-4">
                {dynaimcInputSelectField({
                  register,
                  errors,
                  label: "E -Payment",
                  name: `products.${accountIndex}.cashType`,
                  value: "name",
                  placeholder: "",
                  isRequired: false,
                  data: [
                    {
                      name: "UPI",
                      shortName: "upi",
                      _id: "UPI",
                    },
                    {
                      name: "NEFT",
                      shortName: "neft",
                      _id: "NEFT",
                    },
                    {
                      name: "CHEQUE",
                      shortName: "cheque",
                      _id: "CHEQUE",
                    },
                  ],
                  readOnly: false,
                  onChange: (e) => {
                    console.log(e)
                    setValue("cashType", e);
                    setSelectedCashType(e)
                    setCount(count + 1);
                  },
                  wrapperClass: "col-span-4",
                })}

                {(selectedCashType==='UPI' || selectedCashType==='NEFT') && inputText({
                  register,
                  errors,
                  isRequired: false,
                  name: `products.${accountIndex}.refNo`,
                  placeholder: "",
                  label: "Reference Number",
                  wrapperClass: "col-span-4",
                })}
                { selectedCashType==='CHEQUE'  && inputText({
                  register,
                  errors,
                  isRequired: false,
                  name: `products.${accountIndex}.chequeNo`,
                  placeholder: "",
                  label: "Cheque Number",
                  wrapperClass: "col-span-4",
                })}
                {selectedCashType==='CHEQUE'  && inputDate({
                  register,
                  errors,
                  isRequired: false,
                  name: `products.${accountIndex}.chequeDate`,
                  label: "Cheque Date",
                  wrapperClass: "col-span-4",
                })}
                {inputText({
                  register,
                  errors,
                  isRequired: false,
                  name: `products.${accountIndex}.bankName`,
                  placeholder: "",
                  label: "Bank Name",
                  wrapperClass: "col-span-8",
                })}
              </div>
            </div>
          </DialogPanel>
        </div>
        <DialogBackdrop className="fixed z-[999] inset-0 bg-black/50" />
      </Dialog>

      <div className="flex-1 mb-3 overflow-y-auto">
        {/* Product Table */}
        <h3 className="text-base font-bold text-gray-800 mb-2">Products</h3>

        <div className="relative overflow-x-auto rounded-lg border border-slate-300">
          <table className="w-full text-sm text-left text-gray-800 ">
            <tbody>
              {fields.map((item, index) => (
                <tr
                  key={item.id}
                  className="border-b last:border-0 hover:bg-slate-100"
                >
                  <td className="border-b  border-gray-300 p-1 text-center">
                    {index + 1}
                  </td>
                  <td className="border-b  border-l border-gray-300">
                    <InputAutoCompleteSelect
                      register={register}
                      errors={errors}
                      isRequired={false}
                      id="itemListOption"
                      data={customerDropdownData}
                      onChange={(e) => handleSelectItem(e, index)}
                      name={`products.${index}.accountName`}
                      placeholder="Account Name"
                      readOnly={false}
                      wrapperClass="!m-0"
                      inputClass="rounded-none ring-transparent"
                    />
                  </td>
                  <td className="border-b  border-l border-gray-300">
                    {inputText({
                      register,
                      errors,
                      isRequired: false,
                      name: `products.${index}.amountType`,
                      placeholder: "Amount *",
                      readOnly: true,
                      wrapperClass: "!m-0",
                      inputClass: "rounded-none ring-transparent",
                    })}
                  </td>
                  <td className="border-b  border-l border-gray-300">
                    {inputNumber({
                      register,
                      errors,
                      name: `products.${index}.amount`,
                      placeholder: "Amount",
                      onChange:(e)=>{
                          setValue(`products.${index}.amount`,e)
                          handleAmountChange(index)
                      },
                      readOnly: false,
                      wrapperClass: "!m-0",
                      inputClass: "rounded-none ring-transparent",
                    })}
                  </td>

                  <td className="border-b  border-l border-gray-300">
                    {inputNumber({
                      register,
                      errors,
                      name: `products.${index}.discount`,
                      placeholder: "Discount",
                      onChange:(e)=>{
                        setValue(`products.${index}.discount`,e)
                        handleAmountChange(index)
                    },
                      isRequired: false,
                      readOnly: false,
                      wrapperClass: "!m-0",
                      inputClass: "rounded-none ring-transparent",
                    })}
                  </td>
                  <td className="border-b  border-l border-gray-300">
                    {inputNumber({
                      register,
                      errors,
                      name: `products.${index}.total`,
                      placeholder: "Total",
                      readOnly: true,
                      wrapperClass: "!m-0",
                      inputClass: "rounded-none ring-transparent",
                    })}
                  </td>

                  <td className="border-b  border-l border-gray-300 text-center">
                    <button
                      type="button"
                      onClick={() => bbFunction(index)}
                      className="bg-white border border-blue-600 text-blue-600 px-2 py-1 rounded"
                    >
                      BB
                    </button>
                  </td>
                  <td className="border-b  border-l border-gray-300 text-center" style={{ width: "50px" }}>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => {
                          remove(index);
                          setCount(count + 1);
                        }}
                        className="text-red-500 border border-transparent hover:bg-white hover:border-red-600 px-1 py-1 rounded-full"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="20"
                          viewBox="0 -960 960 960"
                          width="20"
                          fill="currentColor"
                        >
                          <path d="M292.31-140q-29.92 0-51.12-21.19Q220-182.39 220-212.31V-720h-40v-60h180v-35.38h240V-780h180v60h-40v507.69Q740-182 719-161q-21 21-51.31 21H292.31ZM680-720H280v507.69q0 5.39 3.46 8.85t8.85 3.46h375.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46V-720ZM376.16-280h59.99v-360h-59.99v360Zm147.69 0h59.99v-360h-59.99v360ZM280-720v520-520Z" />
                        </svg>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button
          type="button"
          onClick={() => {
            append({});
            setCount(count + 1);
          }}
          className="bg-green-600 text-white px-3 py-1 rounded mt-2 mb-5"
        >
          Add Account
        </button>
      </div>
      <div className=" bg-white rounded-t-xl border pt-2 px-3 -mx-3 -mb-3">
        <div className="grid grid-cols-12 gap-x-4">
          <div className="col-span-6 relative overflow-x-auto rounded-lg border border-slate-300">
            <table className="w-full text-sm text-left text-gray-800 ">
              <tbody>
                <tr>
                  <th className="p-2 text-right align-top">Narration</th>
                  <td className="p-2 border-l border-gray-300" colSpan="4">
                    {inputTextArea({
                      wrapperClass: "!m-0",
                      register,
                      errors,
                      name: "narration",
                      placeholder: "Write here...",
                      readOnly: false,
                      isRequired: false,
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-span-6 relative overflow-x-auto rounded-lg border border-slate-300">
            <table className="w-full text-sm text-left text-gray-800 ">
              <tbody>
                <tr>
                  <th className="p-1 text-right">Grand Total</th>
                  <td className="p-2">
                    {inputNumber({
                      register,
                      errors,
                      placeholder: "",
                      name: `grandTotal`,
                      readOnly: true,
                      wrapperClass: "!m-0",
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex border-t pt-2 pb-4 mt-2 gap-x-3">
          <button
            type="submit"
            className="min-w-[120px] inline-flex items-center justify-center gap-1 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm p-1 h-8 text-center "
            onClick={handleSubmit(submitHandler)}
          >
            Save
          </button>
          <button
            type="button"
            className="px-3 py-0 h-8 inline-flex items-center gap-x-2 text-sm font-semibold rounded border border-gray-300 bg-white text-gray-800 hover:bg-gray-100  hover:shadow focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2 focus:ring-offset-white active:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
            onClick={() => reset()}
          >
            Clear
          </button>
        </div>
      </div>
      <Dialog
        open={isBB}
        onClose={() => {
          setIsBB(false);
        }}
        transition
        className="realtive z-[1000] transition duration-100 ease-linear data-[closed]:opacity-0"
      >
        <div className="fixed z-[1000] inset-0 flex w-screen justify-center items-center p-2">
          <DialogPanel className="w-full max-w-[800px] max-h-[calc(100vh_-_1rem)] overflow-auto flex flex-col rounded-xl shadow-sm bg-white">
            <DialogTitle
              className="flex justify-between items-center p-3"
              as="div"
            >
              <h3 className="text-2xl font-bold">Due Details</h3>

              <button
                type="button"
                className="inline-flex text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-600 focus-visible:ring-4 transition duration-150 ease-linear p-1"
                aria-label="Close"
                onClick={() => {
                  setIsBB(false);
                }}
              >
                <span className="material-symbols-rounded">close</span>
              </button>
            </DialogTitle>
            <div className="p-2">
              <div className="relative overflow-x-auto rounded-lg border border-slate-300">
                <table className="w-full text-sm text-left text-gray-800 ">
                  <thead className="text-xs text-slate-800 bg-slate-100 border-b-2 border-slate-300">
                    <tr>
                      <th className="px-2 py-3">Date</th>
                      <th className="px-2 py-3 border-l border-gray-300">
                        Invoice No
                      </th>
                      <th className="px-2 py-3 border-l border-gray-300">
                        Invoice Amount
                      </th>
                      <th className="px-2 py-3 border-l border-gray-300">
                        Due Days
                      </th>
                      <th className="px-2 py-3 border-l border-gray-300">
                        Select
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      selectedCustomerSalesData && selectedCustomerSalesData.length>0 ?
                      selectedCustomerSalesData.map((x)=>{
                        return (
                          <tr>
                            <td>
                              {x.InvoiceNo}
                            </td>
                            <td>
                              {x.invoiceDate}
                            </td>
                            <td>
                              {x.totalAmount}
                            </td>
                            <td>
                              {x.invoiceDate}
                            </td>
                            <td>
                            <label className="relative inline-block w-12 h-6">
      <input
        type="checkbox"
        className="peer sr-only"
        checked={x?.payedAmount>0}
        onChange={()=>handleToggle(x._id)}
      />
      <span
        className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-300 rounded-full 
        peer-checked:bg-green-500 transition-colors duration-300"
      ></span>
      <span
        className="absolute bg-white h-5 w-5 rounded-full top-0.5 left-0.5 
        peer-checked:translate-x-6 transition-transform duration-300"
      ></span>
    </label>
                            </td>
                          </tr>
                        )
                      }):
                      <tr>
                        <td colSpan="5">
                          <div className="text-center p-5 text-gray-600">
                            No data available.
                          </div>
                        </td>
                      </tr>
                    }
                    
                  </tbody>
                </table>
              </div>
            </div>
          </DialogPanel>
        </div>
        <DialogBackdrop className="fixed z-[999] inset-0 bg-black/50" />
      </Dialog>
    </div>
  );
};

export default ReceiptVoucher;
