import { useEffect, useState } from "react";
import { Message } from "../../components";
import {
  inputHidden,
  inputCheckBox,
  inputSwitch,
  inputEmail,
  inputText,
  staticInputSelect,
  staticInputSelectState,
  inputDate,
  staticInputSelectCity,
  inputImgFile,
} from "../../utils/dynamicForm";
import useUploadHook from "../../api/upload";

// import { useState } from "react";

export const FormBranches = ({
  view,
  edit,
  formCleanHandler,
  isLoading,
  register,
  isError,
  errors,
  watch,
  isLoadingUpdate,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  error,
  setIsModalOpen,
  nextSequenceNumber,
  company,
  states,
  cities,
  setSelectedFile,
  imageFile,
}) => {
  const getDynamicLabel = (field, value) => {
    if (field === "blocked") {
      return value ? "Active" : "Inactive";
    }
    return field;
  };
  const [fileSelected,setFileSelected]=useState(null)
  const [isFullScreen,setIsFullScreen]=useState(false)
  const [fileLink, setFileLink] = useState(null);
  const [city, setCity] = useState([]);
  const [getTrue, setTrue] = useState(false);
  const [stateShortName, setStateShortName] = useState("");
  const [cityShortName, setCityShortName] = useState("");
  const [sequenceNumber, setSequenceNumber] = useState(1);
  const [companyID, setCompanyID] = useState("");
  const { postUpload } = useUploadHook();
  const {
    data: dataUpload,
    isLoading: isLoadingUpload,
    isError: isErrorUpload,
    error: errorUpload,
    mutateAsync: mutateAsyncUpload,
    isSuccess: isSuccessUpload,
  } = postUpload;
  const handleStateChange = (e) => {
    setTrue(true);
    const id = e.target.selectedOptions[0].dataset.id;
    const shortName = e.target.selectedOptions[0].dataset.shortname;
    if (id !== "") {
      setStateShortName(shortName);
      const filteredCities = cities
        .filter((item) => item?.state.map(x=> x._id).includes(id))
        .map((item) => ({
          name: item.cityName,
          shortName: item.cityShortName,
        }));
      setCity(filteredCities);
    } else {
      setCity([]);
    }
  };
  const handleCityChange = (e) => {
    const cityShortName = e.target.selectedOptions[0].dataset.shortname;
    setCityShortName(cityShortName);
  };
  const handleFile = (e) => {
    console.log("Event data:", e, e.target.files);
    const file = e.target.files && e.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }
    console.log("Event data:", file);
    const formData = new FormData();
    formData.append("file", file);
    console.log("Event data:");
    for (let pair of formData.entries()) {
      console.log(pair[0] + ":", pair[1]);
    }
    mutateAsyncUpload({ type: "image", formData })
      .then((response) => {
        console.log("Event data:", response);
        if (
          response &&
          response.filePaths &&
          response.filePaths[0] &&
          response.filePaths[0].path
        ) {
          setFileLink(response.filePaths[0].path);
        }
      })
      .catch((error) => {
        console.error("Event data:", error);
      });
  };

  useEffect(() => {
    if (stateShortName && cityShortName) {
      setCompanyID(
        `${stateShortName}${cityShortName}` +
          String(nextSequenceNumber > 0 ? nextSequenceNumber : 1).padStart(
            5,
            "0"
          ) +
          "CB"
      );
    }
  }, [stateShortName, cityShortName]);

  return (
    <>
      {isLoading ? (
        <div className="w-full top-0 left-0 z-[999]">
          <div className="h-1 w-full bg-blue-100 overflow-hidden">
            <span className="sr-only">Loading...</span>
            <div className="animate-progress w-full h-full bg-blue-500 origin-left-right"></div>
          </div>
        </div>
      ) : isError ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <form onSubmit={handleSubmit(submitHandler)}>
          {inputHidden({
            register,
            errors,
            label: "",
            name: "sequenceNumber",
            placeholder: "Sequence Number",
            value: nextSequenceNumber > 0 ? nextSequenceNumber : 1,
            readOnly: true,
          })}
          {inputText({
            register,
            errors,
            label: "Branch Code",
            name: "branchSerialNo",
            placeholder: "Branch Code",
            value:
              "BRN" +
              String(nextSequenceNumber > 0 ? nextSequenceNumber : 1).padStart(
                5,
                "0"
              ),
            readOnly: true,
          })}
          <div className="grid grid-cols-12 gap-3">
            {inputDate({
              register,
              errors,
              label: "Registration Date",
              name: "registrationDate",
              placeholder: "11/11/2024",
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
            {staticInputSelect({
              register,
              errors,
              label: "Company ID",
              name: "companyID",
              placeholder: "Company ID",
              isRequired: true,
              data:
                company &&
                company.map((item) => ({
                  name: item.companyID,
                  _id: item._id,
                })),
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
          </div>
          <div className="grid grid-cols-12 gap-3">
            {staticInputSelectState({
              register,
              errors,
              label: "State",
              name: "state",
              placeholder: "State",
              isRequired: true,
              data:
                states &&
                states.map((item) => ({
                  name: item.stateName,
                  _id: item._id,
                  shortName: item.stateShortName,
                })),
              onChange: handleStateChange,
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
            {staticInputSelectCity({
              register,
              errors,
              label: "City",
              name: "city",
              placeholder: "City",
              isRequired: true,
              data: edit && !getTrue ? [{ name: watch("city") }] : city && city,
              onChange: handleCityChange,
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
            {view || edit ? (
              <>
                {inputText({
                  register,
                  errors,
                  label: "Branch ID",
                  name: "branchID",
                  isRequired: false,
                  readOnly: true,
                  wrapperClass: "col-span-4",
                })}
              </>
            ) : (
              <>
                {inputText({
                  register,
                  errors,
                  label: "Branch ID",
                  name: "branchID",
                  value: companyID || "TNCHN12345CO",
                  placeholder: "TNCHN12345CO",
                  readOnly: true,
                  wrapperClass: "col-span-4",
                })}
              </>
            )}
          </div>

          {/* {inputText({
            register,
            errors,
            label: "User ID",
            name: "user",
            placeholder: "User ID",
            readOnly: view,
          })} */}
          <div className="grid grid-cols-12 gap-3">
            {inputText({
              register,
              errors,
              label: "Branch Name",
              name: "branchName",
              placeholder: "Branch Name",
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
            {inputText({
              register,
              errors,
              label: "Branch Short Name",
              name: "branchShortName",
              placeholder: "Branch Short Name",
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
            {inputText({
              register,
              errors,
              label: "GSTIN Number",
              name: "gSTINNumber",
              placeholder: "GSTIN Number",
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
          </div>
          <div className="grid grid-cols-12 gap-x-3">
            {inputText({
              register,
              errors,
              label: "Address 1",
              name: "address1",
              placeholder: "House/Flat no, Building name",
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
            {inputText({
              register,
              errors,
              label: "Address 2",
              name: "address2",
              placeholder: "Street name/number",
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
            {inputText({
              register,
              errors,
              label: "Address 3",
              name: "address3",
              placeholder: "Block no. , Area Name",
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
            {inputText({
              register,
              errors,
              label: "Pin code",
              name: "pincode",
              placeholder: "600 078",
              readOnly: view,
              wrapperClass: "col-span-6",
            })}
          </div>
          <div className="grid grid-cols-12 gap-3">
            {inputText({
              register,
              errors,
              label: "Mobile Number",
              name: "mobileNumber",
              placeholder: "9876543210",
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
            {inputText({
              register,
              errors,
              label: "Phone Number",
              name: "phoneNumber",
              placeholder: "04412345678",
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
            {inputEmail({
              register,
              errors,
              label: "Email ID",
              name: "email",
              placeholder: "Email",
              readOnly: view,
              wrapperClass: "col-span-4",
            })}
          </div>
          {
            !view?
          inputImgFile({
                register,
                errors,
                label: "Logo",
                name: "logo",
                placeholder: "Logo",
                readOnly: view,
                isRequired:false,
                onChange:(e)=>{
                  const file = e.target.files[0];

                  if (file) {
                    const reader = new FileReader();

                    reader.onload = (e) => {
                      const dataURL = e.target.result;
                      const fileObject = {
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        lastModified: file.lastModified,
                        content: dataURL
                      };
                      setSelectedFile(fileObject)
                      setFileSelected(fileObject)
                    };
                
                    reader.onerror = (e) => {
                      console.error('Error reading file:', e.target.error);
                    };
                
                    reader.readAsDataURL(file); // Reading the file as a Data URL
                    

                    
                  } else {
                    console.log('No file selected');
                  }
                },
                wrapperClass: "col-span-3",
              }):
              <label
        className="block font-semibold text-gray-800 mb-0.5 text-xs"
      >
        Logo
      </label>
            }
              {
                (imageFile && !fileSelected) &&
                <div className="relative">
                <img
                  src={imageFile}
                  alt={"altText"}
                  className={`cursor-pointer transition-all duration-300 ${
                    isFullScreen ? "fixed inset-0 z-50 w-full h-auto max-h-screen object-contain bg-black" : "w-24 h-24"
                  }`}
                  onClick={() => setIsFullScreen(!isFullScreen)}
                />
                {isFullScreen && (
                  <button
                    onClick={() => setIsFullScreen(false)}
                    className="absolute top-4 right-4 z-50 p-2 bg-gray-700 text-white rounded hover:bg-gray-900"
                  >
                    Close
                  </button>
                )}
            </div>  
              }
          
          {inputText({
            register,
            errors,
            label: "Watermark",
            name: "watermark",
            placeholder: "Watermark",
            readOnly: view,
          })}
          {inputSwitch({
            register,
            errors,
            watch,
            name: "blocked",
            label: getDynamicLabel("blocked", watch("blocked")),
            isRequired: false,
            placeholder: "Blocked",
            readOnly: view,
          })}
          {view ? (
            ""
          ) : (
            <div className="flex mt-1 pt-3 border-t gap-3">
              <button
                type="submit"
                className="min-w-[120px] inline-flex items-center justify-center gap-1 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm px-3 py-0 h-8 text-center "
                disabled={isLoadingPost || isLoadingUpdate}
              >
                {isLoadingPost || isLoadingUpdate ? (
                  <span
                    className="animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full "
                    role="status"
                    aria-label="loading"
                  >
                    <span className="sr-only">Loading...</span>
                  </span>
                ) : (
                  <span>{edit ? "Update" : "Save"}</span>
                )}
              </button>
              <button
                type="button"
                className="px-3 py-0 h-8 inline-flex items-center gap-x-2 text-sm font-semibold rounded border border-gray-300 bg-white text-gray-800 hover:bg-gray-100  hover:shadow focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2 focus:ring-offset-white active:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
                onClick={() => {
                  setIsModalOpen(false);
                  formCleanHandler();
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default FormBranches;
