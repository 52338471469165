import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FormItems from "./FormItems";
import useItemsHook from "../../api/items";
import { useForm } from "react-hook-form";
import useGSTTaxesHook from "../../api/gstTaxes";
import useHSNsHook from "../../api/hsns";
import useCategoriesHook from "../../api/categories";
import useBranchesHook from "../../api/branches";
import useItemGroupsHook from "../../api/itemGroups";
import useUnitConversionsHook from "../../api/unitConversions";
const AddItem = () => {
  const location=useLocation();
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const { paramid, type } = useParams();
  const [edit, setEdit] = useState(type === "edit");
  const [view, setView] = useState(type === "view");
  const [insertCount, setInsertCount] = useState(0);
  const [selectedFile,setSelectedFile]=useState(null)
  const navigate = useNavigate();
  const { getItems, postItem, updateItem, deleteItem, getItemById } =
    useItemsHook({
      page,
      id: paramid,
      q,
    });
  const [id, setId] = useState(null);
  const { getBranches } = useBranchesHook({
    page,
    q,
  });

  const { getItemGroups } = useItemGroupsHook({
    page,
    q,
  });

  const { getCategories } = useCategoriesHook({
    page,
    q,
  });

  const { getHSNs } = useHSNsHook({
    page,
    q,
  });

  const { getGSTTaxes } = useGSTTaxesHook({
    page,
    q,
  });
  const { getUnitConversions } = useUnitConversionsHook({
    page,
    q,
  });

  const { data, isLoading, isError, error, refetch } = getItems;
  const [selectedData, setSelectedData] = useState({});
  useEffect(()=>{
    formCleanHandler()
  },[location])
  useEffect(() => {
    if (data !== undefined && data.data.length == 1) {
      editHandler(data.data[0]);
    }
  }, [data]);
  const { data: getBranchName } = getBranches;
  const { data: getItemGroup } = getItemGroups;
  const { data: getCategory } = getCategories;
  const { data: getUom } = getUnitConversions;
  const { data: getHSNcode } = getHSNs;
  const { data: getGST } = getGSTTaxes;
  const {
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
    isSuccess: isSuccessUpdate,
    mutateAsync: mutateAsyncUpdate,
  } = updateItem;
  const {
    isLoading: isLoadingPost,
    isError: isErrorPost,
    error: errorPost,
    isSuccess: isSuccessPost,
    mutateAsync: mutateAsyncPost,
  } = postItem;
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const formCleanHandler = () => {
    //setEdit(false);
    reset();
  };
  const submitHandler = (data) => {
    if (id) {
      mutateAsyncUpdate({
        _id: id,
        itemSerialNo: data.itemSerialNo,
        branchName: data.branchName,
        groupName: data.groupName,
        productCategory: data.productCategory,
        name: data.name,
        aliasName: data.aliasName,
        uom: data.uom,
        cost: data.cost,
        listPrice: data.listPrice,
        discount: data.discount,
        productImage:selectedFile?.name?selectedFile.name :'',
      file:selectedFile,
        marginPrice: data.marginPrice,
        MRP: data.MRP,
        batchNo: data.batchNo,
        expiryDate: data.expiryDate,
        freeGiftQty: data.freeGiftQty,
        HSNCode: data.HSNCode,
        GSTTaxRate: data.GSTTaxRate,
        reOrderQty: data.reOrderQty,
        openingStockQty: data.openingStockQty,
        openingStockValue: data.openingStockValue,
        blocked: data.blocked,
      }).then(() => {
        navigate("/admin/auth/items");
      });
    } else {
      var sequenceCode =
        "ITM" +
        String(
          parseInt(data.sequenceNumber) > 0
            ? parseInt(data.sequenceNumber) + insertCount
            : 1
        ).padStart(5, "0");
      data.sequenceNumber = "" + (parseInt(data.sequenceNumber) + insertCount);
      data.nextSequenceNumber = sequenceCode;
      data.itemSerialNo = sequenceCode;
      data.productImage=selectedFile?.name?selectedFile.name :'';
      data.file=selectedFile
      mutateAsyncPost(data);
       setInsertCount(insertCount + 1);
      refetch();
      formCleanHandler();
    }
  };
  const editHandler = (item) => {
    setId(item._id);
    // setView(false);
    // setEdit(true);
    setValue("itemSerialNo", item.itemSerialNo);
    setValue("branchName", item.branchName);
    setValue("groupName", item.groupName);
    setValue("productCategory", item.productCategory);
    setValue("name", item.name);
    setValue("aliasName", item.aliasName);
    setValue("uom", item.uom);
    setValue("cost", item.cost);
    setValue("listPrice", item.listPrice);
    setValue("discount", item.discount);
    setValue("marginPrice", item.marginPrice);
    setValue("MRP", item.MRP);
    setValue("batchNo", item.batchNo);
    setValue("expiryDate", item.expiryDate);
    setValue("freeGiftQty", item.freeGiftQty);
    setValue("HSNCode", item.HSNCode);
    setValue("GSTTaxRate", item.GSTTaxRate);
    setValue("reOrderQty", item.reOrderQty);
    setValue("openingStockQty", item.openingStockQty);
    setValue("openingStockValue", item.openingStockValue);
    setValue("productImage", item.productImage);
    setValue("blocked", item.blocked);
  };

  return (
    <>
      <div className="max-w-[1660px] mx-auto">
        <h1 className="text-2xl font-bold mb-4">Add Item</h1>
        <FormItems
          edit={edit}
          view={view}
          formCleanHandler={formCleanHandler}
          isLoading={isLoading}
          isError={isError}
          errors={errors}
          isLoadingUpdate={isLoadingUpdate}
          isLoadingPost={isLoadingPost}
          register={register}
          handleSubmit={handleSubmit}
          submitHandler={submitHandler}
          setIsModalOpen={true}
          watch={watch}
          error={error}
          nextSequenceNumber={data && data.nextSequenceNumber}
          branch={getBranchName && getBranchName.data}
          group={getItemGroup && getItemGroup.data}
          category={getCategory && getCategory.data}
          uom={getUom && getUom.data}
          hsn={getHSNcode && getHSNcode.data}
          gst={getGST && getGST.data}
          setSelectedFile={(obj)=>setSelectedFile(obj)}
          imageFile={(data?.data && data?.data?.length>0 && (edit || view)) ?data.data[0]?.imageUrl:null}
        />
      </div>
    </>
  );
};
export default AddItem;
