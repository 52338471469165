import { Link } from "react-router-dom";
import { Spinner, Message } from "..";
import {
  inputHidden,
  inputCheckBox,
  inputSwitch,
  inputText,
  dynaimcInputSelectField,
  inputImgFile,
} from "../../utils/dynamicForm";
import { useState } from "react";

const FormItems = ({
  edit,
  view,
  formCleanHandler,
  isLoading,
  register,
  isError,
  watch,
  errors,
  isLoadingUpdate,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  error,
  setIsModalOpen,
  nextSequenceNumber,
  branch,
  group,
  category,
  uom,
  hsn,
  gst,
  setSelectedFile,
  imageFile,
}) => {
  const [isFullScreen, setIsFullScreen] = useState();
  const [fileSelected, setFileSelected] = useState(null);
  const getDynamicLabel = (field, value) => {
    if (field === "blocked") {
      return value ? "Active" : "Inactive";
    }
    return field;
  };
  return (
    <>
      <div className="bg-white shadow-lg rounded-lg border p-4">
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <form onSubmit={handleSubmit(submitHandler)}>
            {inputHidden({
              register,
              errors,
              label: "",
              name: "sequenceNumber",
              placeholder: "Sequence Number",
              value: nextSequenceNumber > 0 ? nextSequenceNumber : 1,
              readOnly: true,
            })}
            <div className=" font-bold text-base mb-2 mt-3">Item Details</div>
            <div className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-12 gap-x-3">
              {inputText({
                register,
                errors,
                label: "Item Code",
                name: "itemSerialNo",
                placeholder: "Item Code",
                value:
                  "ITM" +
                  String(
                    nextSequenceNumber > 0 ? nextSequenceNumber : 1
                  ).padStart(5, "0"),
                readOnly: false,
                wrapperClass: "col-span-3",
              })}
              {inputText({
                register,
                errors,
                label: "Name",
                name: "name",
                placeholder: "Name",
                isRequired: true,
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {inputText({
                register,
                errors,
                label: "Alias Name",
                name: "aliasName",
                placeholder: "Alias Name",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {dynaimcInputSelectField({
                register,
                errors,
                label: "Uom",
                name: "uom",
                placeholder: "Uom",
                isRequired: true,
                data: uom && uom,
                value: "subUnit",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {dynaimcInputSelectField({
                register,
                errors,
                label: "HSN Code",
                name: "HSNCode",
                placeholder: "HSN Code",
                isRequired: false,
                data: hsn && hsn,
                value: "hSNCode",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {dynaimcInputSelectField({
                register,
                errors,
                label: "Product Category",
                name: "productCategory",
                placeholder: "Product Category",
                isRequired: true,
                data: category && category,
                value: "brandName",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {dynaimcInputSelectField({
                register,
                errors,
                label: "Branch Name",
                name: "branchName",
                placeholder: "Branch Name",
                isRequired: false,
                data: branch && branch,
                value: "branchName",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {dynaimcInputSelectField({
                register,
                errors,
                label: "Group Name",
                name: "groupName",
                placeholder: "Group Name",
                isRequired: true,
                data: group && group,
                value: "itemGroup",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
            </div>
            <div className=" font-bold text-base mb-2 mt-3">Pricing</div>
            <div className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-12 gap-x-3">
              {inputText({
                register,
                errors,
                label: "Cost",
                name: "cost",
                placeholder: "Cost",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {inputText({
                register,
                errors,
                label: "List Price",
                name: "listPrice",
                placeholder: "List Price",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {inputText({
                register,
                errors,
                label: "Discount",
                name: "discount",
                placeholder: "Discount",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {inputText({
                register,
                errors,
                label: "Margin Price",
                name: "marginPrice",
                placeholder: "Margin Price",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {inputText({
                register,
                errors,
                label: "MRP",
                name: "MRP",
                placeholder: "MRP",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
            </div>
            <div className=" font-bold text-base mb-2 mt-3">Inventory</div>
            <div className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-12 gap-x-3">
              {inputText({
                register,
                errors,
                label: "Re-Order Qty",
                name: "reOrderQty",
                placeholder: "Re-Order Qty",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {inputText({
                register,
                errors,
                label: "Opening Stock Qty",
                name: "openingStockQty",
                placeholder: "Opening Stock Qty",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {inputText({
                register,
                errors,
                label: "Opening Stock Value",
                name: "openingStockValue",
                placeholder: "Opening Stock Value",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {inputText({
                register,
                errors,
                label: "Batch No",
                name: "batchNo",
                placeholder: "Batch No",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {inputText({
                register,
                errors,
                label: "Expiry Date",
                name: "expiryDate",
                placeholder: "Expiry Date",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
            </div>
            <div className=" font-bold text-base mb-2 mt-3">Tax & Others</div>
            <div className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-12 gap-x-3">
              {dynaimcInputSelectField({
                register,
                errors,
                label: "GST Tax Rate",
                name: "GSTTaxRate",
                placeholder: "GST Tax Rate",
                isRequired: false,
                data: gst && gst,
                value: "gSTTax",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
              {inputText({
                register,
                errors,
                label: "Free Gift Qty",
                name: "freeGiftQty",
                placeholder: "Free Gift Qty",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}

              {view ? (
                <label className="block font-semibold text-gray-800 mb-0.5 text-xs">
                  Product Image
                </label>
              ) : (
                inputImgFile({
                  register,
                  errors,
                  label: "Product Image",
                  name: "productImage",
                  placeholder: "Product Image",
                  readOnly: view,
                  onChange: (e) => {
                    const file = e.target.files[0];

                    if (file) {
                      const reader = new FileReader();

                      reader.onload = (e) => {
                        const dataURL = e.target.result;
                        const fileObject = {
                          name: file.name,
                          type: file.type,
                          size: file.size,
                          lastModified: file.lastModified,
                          content: dataURL,
                        };
                        setSelectedFile(fileObject);
                        setFileSelected(fileObject);
                      };

                      reader.onerror = (e) => {
                        console.error("Error reading file:", e.target.error);
                      };

                      reader.readAsDataURL(file); // Reading the file as a Data URL
                    } else {
                      console.log("No file selected");
                    }
                  },
                  wrapperClass: "col-span-3",
                })
              )}
              {imageFile && !fileSelected && (
                <div className="relative">
                  <img
                    src={imageFile}
                    alt={"altText"}
                    className={`cursor-pointer transition-all duration-300 ${
                      isFullScreen
                        ? "fixed inset-0 z-50 w-full h-auto max-h-screen object-contain bg-black"
                        : "w-24 h-24"
                    }`}
                    onClick={() => setIsFullScreen(!isFullScreen)}
                  />
                  {isFullScreen && (
                    <button
                      onClick={() => setIsFullScreen(false)}
                      className="absolute top-4 right-4 z-50 p-2 bg-gray-700 text-white rounded hover:bg-gray-900"
                    >
                      Close
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="my-3">
              {inputSwitch({
                register,
                errors,
                watch,
                name: "blocked",
                label: getDynamicLabel("blocked", watch("blocked")),
                isRequired: false,
                placeholder: "Blocked",
                readOnly: view,
                wrapperClass: "col-span-3",
              })}
            </div>
            {view ? (
              ""
            ) : (
              <div className="flex mt-1 pt-3 border-t gap-3">
                <button
                  type="submit"
                  className="min-w-[120px] inline-flex items-center justify-center gap-1 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm px-3 py-0 h-8 text-center "
                  disabled={isLoadingPost || isLoadingUpdate}
                >
                  {isLoadingPost || isLoadingUpdate ? (
                    <span
                      className="animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full"
                      role="status"
                      aria-label="loading"
                    >
                      <span className="sr-only">Loading...</span>
                    </span>
                  ) : (
                    <span>{edit ? "Update" : "Save"}</span>
                  )}
                </button>
                <Link
                  to={"/admin/auth/items"}
                  className="px-3 py-0 h-8 inline-flex items-center gap-x-2 text-sm font-semibold rounded border border-gray-300 bg-white text-gray-800 hover:bg-gray-100  hover:shadow focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2 focus:ring-offset-white active:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
                  // onClick={() => {
                  //   setIsModalOpen(false);
                  //   formCleanHandler();
                  // }}
                >
                  Cancel
                </Link>
              </div>
            )}
          </form>
        )}
      </div>
    </>
  );
};

export default FormItems;
