import { Link } from "react-router-dom";
import { Search } from "..";
import Tooltip from "../../utils/Tooltip";

const ViewSalesVoucher = ({
  data,
  viewHandler,
  editHandler,
  deleteHandler,
  isLoadingDelete,
  setQ,
  q,
  searchHandler,
  setIsModalOpen,
  setView,
}) => {
  return (
    <>
      <div className="flex flex-wrap items-center justify-between mb-3">
        <h2 className="font-bold text-2xl text-gray-800 my-1">Sales Voucher</h2>
        <div className="flex flex-wrap gap-3">
          <Search
            placeholder="Search by name"
            setQ={setQ}
            q={q}
            searchHandler={searchHandler}
          />
          <Link
            to={"/admin/auth/add-sale"}
            className="inline-flex items-center gap-1 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm px-3 py-1 text-center"
            // onClick={() => {
            //   setIsModalOpen(true);
            //   setView(false);
            // }}
          >
            <span className="material-symbols-rounded">add</span>
            <span className="">New Sales Voucher</span>
          </Link>
        </div>
      </div>
      <div className="relative overflow-x-auto rounded-lg border border-slate-300">
        <table className="table-fixed w-full text-sm text-left text-gray-800 ">
          <thead className="text-xs text-slate-800 bg-slate-100 border-b-2 border-slate-300">
            <tr>
              <th className="px-2 py-3" style={{ width: "50px" }}>
                Sl.No
              </th>
              <th className="px-2 py-3" style={{ width: "100px" }}>Invoice NO</th>
              <th className="px-2 py-3" style={{ width: "100px" }}>Customer Name</th>
              <th className="px-2 py-3" style={{ width: "200px" }}>Customer Address</th>
              <th className="px-2 py-3" style={{ width: "200px" }}>Sales Type</th>
              <th className="px-2 py-3" style={{ width: "200px" }}>State</th>
              <th className="px-2 py-3" style={{ width: "200px" }}>Grant total</th>
              <th className="px-2 py-3" style={{ width: "200px" }}>Package Amount</th>
              <th className="px-2 py-3" style={{ width: "300px" }}>Contact No</th>
              <th className="px-2 py-3" style={{ width: "300px" }}>Total Amount</th>
              <th className="px-2 py-3" style={{ width: "145px" }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.data.map((item, index) => (
                <tr
                  className={`border-b last:border-0 hover:bg-slate-100 ${
                    !item.blocked
                      ? "hover:shadow-[inset_3px_0_0_0_theme(colors.red.600)]"
                      : ""
                  }`}
                  key={item._id}
                >
                  <td className="p-2">{index + 1}</td>
                  <td className="p-2">{item.InvoiceNo}</td>
                  <td className="p-2">{item.customerName}</td>
                  <td className="p-2">{item.customerAddressPOS}</td>
                  <td className="p-2">{item.saleType}</td>
                  <td className="p-2">{item.state}</td>
                  <td className="p-2">{item.grandTotal}</td>
                  <td className="p-2">{item.packingCharge}</td>
                  <td className="p-2">{item.customerContactPOS}</td>
                  <td className="p-2">{item.totalAmount}</td>
                  <td className="px-2 py-1">
                    <div className="flex flex-wrap">
                      <Tooltip content="View" placement="bottom">
                        <Link
                          to={`/admin/auth/add-sale?paramid=${item._id}&type=view`}
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                        >
                          <span className="material-symbols-rounded ">
                            visibility
                          </span>
                        </Link>
                      </Tooltip>
                      <Tooltip content="Edit" placement="bottom">
                        <Link
                          to={`/admin/auth/add-sale?paramid=${item._id}&type=edit`}
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => {
                            // setIsModalOpen(true);
                            // editHandler(item);
                          }}
                        >
                          <span className="material-symbols-rounded ">
                            edit
                          </span>
                        </Link>
                      </Tooltip>
                      <Tooltip content="Delete" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-red-600 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => deleteHandler(item._id)}
                          disabled={isLoadingDelete}
                        >
                          {isLoadingDelete ? (
                            <span
                              className="animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full"
                              role="status"
                              aria-label="loading"
                            >
                              <span className="sr-only">Loading...</span>
                            </span>
                          ) : (
                            <span className="material-symbols-rounded">
                              delete
                            </span>
                          )}
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ViewSalesVoucher;
