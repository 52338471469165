import moment from "moment";
import { Search } from "..";
import Tooltip from "../../utils/Tooltip";
const ViewCustomers = ({
  data,
  editHandler,
  viewHandler,
  deleteHandler,
  isLoadingDelete,
  setIsModalOpen,
  setQ,
  q,
  searchHandler,
}) => {
  return (
    <>
      <div className="flex flex-wrap items-center justify-between mb-3">
        <h2 className="font-bold text-2xl text-gray-800 my-1">Customers</h2>
        <div className="flex flex-wrap gap-3">
          <Search
            placeholder="Search by email"
            setQ={setQ}
            q={q}
            searchHandler={searchHandler}
          />
          <button
            className="inline-flex items-center gap-1 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm px-3 py-1 text-center"
            onClick={() => setIsModalOpen(true)}
          >
            <span className="material-symbols-rounded">add</span>
            <span className="">New Customer</span>
          </button>
        </div>
      </div>
      <div className="relative overflow-x-auto rounded-lg border border-slate-300">
        <table className="w-full text-sm text-left text-gray-800 table-fixed">
          <thead className="text-xs text-slate-800 bg-slate-100 border-b-2 border-slate-300">
            <tr>
              <th className="px-2 py-3" style={{ width: "50px" }}>
                Sl.No
              </th>
              <th className="px-2 py-3">Customer Code</th>
              <th className="px-2 py-3">Customer Group</th>
              <th className="px-2 py-3">Customer Name</th>
              <th className="px-2 py-3">Name</th>
              <th className="px-2 py-3">Address 1</th>
              <th className="px-2 py-3">Address 2</th>
              <th className="px-2 py-3">Address 3</th>
              <th className="px-2 py-3">City</th>
              <th className="px-2 py-3">Pin code</th>
              <th className="px-2 py-3">State</th>
              <th className="px-2 py-3">Mobile Number</th>
              <th className="px-2 py-3">Email ID</th>
              <th className="px-2 py-3">GSTIN Number</th>
              <th className="px-2 py-3">Pan Number</th>
              <th className="px-2 py-3">Transport Name</th>
              <th className="px-2 py-3">Opening Balance</th>
              <th className="px-2 py-3">Password</th>
              <th className="px-2 py-3">Status</th>
              <th className="px-2 py-3" style={{ width: "160px" }}>
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {data &&
              data.data.map((customer, index) => (
                <tr
                  className={`border-b last:border-0 hover:bg-slate-100 ${
                    !customer.blocked
                      ? "hover:shadow-[inset_3px_0_0_0_theme(colors.red.600)]"
                      : ""
                  }`}
                  key={customer._id}
                >
                  <td className="p-2 truncate">{index + 1}</td>

                  <td className="p-2 truncate">{customer.customerSerialNo}</td>
                  <td className="p-2 truncate">{customer.customerGroup}</td>
                  <td className="p-2 truncate">{customer.customerName}</td>
                  <td className="p-2 truncate">
                    {customer.user?.firstName + " " + customer.user?.lastName}
                  </td>
                  <td className="p-2 truncate">{customer.address1}</td>
                  <td className="p-2 truncate">{customer.address2}</td>
                  <td className="p-2 truncate">{customer.address3}</td>
                  <td className="p-2 truncate">{customer.city}</td>
                  <td className="p-2 truncate">{customer.pincode}</td>
                  <td className="p-2 truncate">{customer.state}</td>
                  <td className="p-2 truncate">{customer.mobileNumber}</td>
                  <td className="p-2 truncate">{customer.user?.email}</td>
                  <td className="p-2 truncate">{customer.GSTINNo}</td>
                  <td className="p-2 truncate">{customer.panNo}</td>
                  <td className="p-2 truncate">{customer.transportName}</td>
                  <td className="p-2 truncate">{customer.openingBalance}</td>
                  <td className="p-2 truncate">{customer.password}</td>
                  <td className="p-2 truncate">
                    {customer.blocked ? (
                      <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                        Active
                      </span>
                    ) : (
                      <span className="text-xs bg-red-100 border border-red-300 text-red-700 rounded-full px-2 py-1">
                        Inactive
                      </span>
                    )}
                  </td>
                  <td className="px-2 py-1">
                    <div className="flex flex-wrap">
                      <Tooltip content="View" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => {
                            setIsModalOpen(true);
                            viewHandler(customer);
                          }}
                        >
                          <span className="material-symbols-rounded">
                            visibility
                          </span>
                        </button>
                      </Tooltip>
                      <Tooltip content="Edit" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onMouseOver={() => {}}
                          onClick={() => {
                            setIsModalOpen(true);
                            editHandler(customer);
                          }}
                        >
                          <span className="material-symbols-rounded ">
                            edit
                          </span>
                        </button>
                      </Tooltip>
                      <Tooltip content="Delete" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-red-600 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => deleteHandler(customer._id)}
                          disabled={isLoadingDelete}
                        >
                          {isLoadingDelete ? (
                            <span
                              className="animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full"
                              role="status"
                              aria-label="loading"
                            >
                              <span className="sr-only">Loading...</span>
                            </span>
                          ) : (
                            <span className="material-symbols-rounded">
                              delete
                            </span>
                          )}
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ViewCustomers;
