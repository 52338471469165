import moment from "moment";
import { Search } from "..";
import Tooltip from "../../utils/Tooltip";
const ViewEmployees = ({
  data,
  editHandler,
  viewHandler,
  deleteHandler,
  isLoadingDelete,
  setIsModalOpen,
  setQ,
  q,
  searchHandler,
  setImageFile
}) => {
  return (
    <>
      <div className="flex flex-wrap items-center justify-between mb-3">
        <h2 className="font-bold text-2xl text-gray-800 my-1">Employees</h2>
        <div className="flex flex-wrap gap-3">
          <Search
            placeholder="Search by email"
            setQ={setQ}
            q={q}
            searchHandler={searchHandler}
          />
          <button
            className="inline-flex items-center gap-1 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm px-3 py-1 text-center"
            onClick={() => {setIsModalOpen(true); setImageFile()}}
          >
            <span className="material-symbols-rounded">add</span>
            <span className="">New Employee</span>
          </button>
        </div>
      </div>
      <div className="relative overflow-x-auto rounded-lg border border-slate-300">
        <table className="table-fixed w-full text-sm text-left text-gray-800 ">
          <thead className="text-xs text-slate-800 bg-slate-100 border-b-2 border-slate-300">
            <tr>
              <th className="p-2 truncate" style={{ width: "50px" }}>
                Sl.No
              </th>
              <th className="px-2 py-3">Employee Code</th>
              <th className="px-2 py-3">Department</th>
              <th className="px-2 py-3">Designation</th>
              <th className="px-2 py-3">Name</th>
              <th className="px-2 py-3">Email</th>
              {/* <th className="px-2 py-3">Address 1</th>
            <th className="px-2 py-3">Address 2</th>
            <th className="px-2 py-3">Address 3</th>
            <th className="px-2 py-3">City</th>
            <th className="px-2 py-3">Pin Code</th>
            <th className="px-2 py-3">State</th>
            <th className="px-2 py-3">Mobile</th>
            <th className="px-2 py-3">PAN No.</th>
            <th className="px-2 py-3">PF No.</th>
            <th className="px-2 py-3">ESI No.</th>
            <th className="px-2 py-3">DOB</th>
            <th className="px-2 py-3">Salary Schedule Type</th>             */}
              <th className="px-2 py-3">Joined Date</th>
              <th className="px-2 py-3">Verification</th>
              <th className="px-2 py-3">Status</th>
              {/* <th className="p-2 truncate">Permission</th> */}
              <th className="px-2 py-3" style={{ width: "145px" }}>
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {data &&
              data.data.map((employee, index) => (
                <tr
                  className={`border-b last:border-0 hover:bg-slate-100 ${
                    !employee.blocked
                      ? "hover:shadow-[inset_3px_0_0_0_theme(colors.red.600)]"
                      : ""
                  }`}
                  key={employee._id}
                >
                  <td className="p-2 truncate">{index + 1}</td>

                  <td className="p-2 truncate">{employee.employeeID}</td>
                  <td className="p-2 truncate">{employee.department.department}</td>
                  <td className="p-2 truncate">{employee.designation.designation}</td>
                  <td className="p-2 truncate">
                    {employee.user?.firstName + " " + employee.user?.lastName}
                  </td>
                  {/* <td className="p-2 truncate">
                    {employee.firstName + " " + employee.lastName}
                  </td> */}
                  <td className="p-2 truncate">
                    <a href={`mailto:${employee.user?.email}`}>
                      {employee.user?.email}
                    </a>
                  </td>
                  {/* <td className="p-2 truncate">{employee.address1}</td>
                <td className="p-2 truncate">{employee.address2}</td>
                <td className="p-2 truncate">{employee.address3}</td>
                <td className="p-2 truncate">{employee.city}</td>
                <td className="p-2 truncate">{employee.pincode}</td>
                <td className="p-2 truncate">{employee.state}</td>
                <td className="p-2 truncate">{employee.mobile}</td>
                <td className="p-2 truncate">{employee.pan}</td>
                <td className="p-2 truncate">{employee.pf}</td>
                <td className="p-2 truncate">{employee.esi}</td>
                <td className="p-2 truncate">{employee.dob}</td>
                <td className="p-2 truncate">{employee.salaryscheduletype}</td> */}
                  <td className="p-2 truncate">
                    {moment(employee.createdAt).format("lll")}
                  </td>
                  <td className="p-2 truncate">
                    {employee.confirmed ? (
                      <Tooltip content="Verified" placement="bottom">
                        <span className="material-symbols-rounded text-green-600">
                          verified_user
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip content="Pending" placement="bottom">
                        <span className="material-symbols-rounded text-red-600">
                          pending
                        </span>
                      </Tooltip>
                    )}
                  </td>
                  <td className="p-2 truncate">
                    {employee.blocked ? (
                      <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                        Active
                      </span>
                    ) : (
                      <span className="text-xs bg-red-100 border border-red-300 text-red-700 rounded-full px-2 py-1">
                        Inactive
                      </span>
                    )}
                  </td>

                  {/* <td className="p-2 truncate">
                    <div className="flex flex-wrap">
                      <button
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => {
                            setIsModalOpen(true);
                            editHandler(employee);
                          }}
                        >                        
                          <span className="material-symbols-rounded ">shield_person</span>
                        </button>
                    </div>
                  </td> */}

                  <td className="px-2 py-1">
                    <div className="flex flex-wrap">
                      <Tooltip content="View" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => {
                            setIsModalOpen(true);
                            viewHandler(employee);
                          }}
                        >
                          <span className="material-symbols-rounded ">
                            visibility
                          </span>
                        </button>
                      </Tooltip>
                      <Tooltip content="Edit" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onMouseOver={() => {}}
                          onClick={() => {
                            setIsModalOpen(true);
                            editHandler(employee);
                          }}
                        >
                          <span className="material-symbols-rounded ">
                            edit
                          </span>
                        </button>
                      </Tooltip>
                      <Tooltip content="Delete" placement="bottom">
                        <button
                          className="inline-flex text-gray-600 hover:text-red-600 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded-full text-sm p-2"
                          onClick={() => deleteHandler(employee._id)}
                          disabled={isLoadingDelete}
                        >
                          {isLoadingDelete ? (
                            <span
                              className="animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full"
                              role="status"
                              aria-label="loading"
                            >
                              <span className="sr-only">Loading...</span>
                            </span>
                          ) : (
                            <span className="material-symbols-rounded">
                              delete
                            </span>
                          )}
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ViewEmployees;
