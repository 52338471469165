import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuthHook from "../api/auth";
import { useMutation } from "react-query";
import { useState, useEffect, useCallback } from "react";
import useAuth from "../hooks/useAuth";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  TransitionChild,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuSeparator,
  MenuHeading,
  MenuSection,
} from "@headlessui/react";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Tooltip from "../utils/Tooltip";
import { clsx } from "clsx";
import useMenusHook from "../api/menus";

const Navigation = ({ toggleSidebar, isSidebarOpen, menu }) => {
  const navigate = useNavigate();
  const { postLogout } = useAuthHook();
  const { auth } = useAuth();
  const { mutateAsync } = useMutation(postLogout, {
    onSuccess: () => navigate("/auth/login"),
  });

  const logoutHandler = () => {
    mutateAsync({});
  };

  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  const UseCurrentPath = () => {
    const location = useLocation();
    return location.pathname;
  };
  const [isOpen, setIsOpen] = useState(false);

  const [activeDisclosurePanel, setActiveDisclosurePanel] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const guestItems = () => {
    return (
      <nav className="">
        <div className="navbar-nav ms-auto">
          <div className="nav-item">
            <Link
              to="/auth/login"
              className="block font-semibold py-1 px-3 rounded border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white transition duration-200 ease"
              aria-current="page"
            >
              Login
            </Link>
          </div>
        </div>
      </nav>
    );
  };

  const user = () => {
    const userInfo = auth?.userInfo;
    return userInfo;
  };

  const { getMenus } = useMenusHook({ limit: 1000000 });
  // console.log("getMenus", getMenus);

  // const menus = () => {
  //   console.log("userInfo--", auth?.userRole);
  //   const dropdownItems = auth?.userRole?.menu?.map((route) => route?.menu);

  //   const menuItems = auth?.userRole?.menu?.map((route) => route);
  //   console.log("menuItems", menuItems);

  //   const dropdownArray =
  //     dropdownItems &&
  //     dropdownItems.filter((item) => item !== "hidden" && item !== "normal");

  //   const uniqueDropdowns = [...new Set(dropdownArray)];
  //   console.log("uniqueDropdowns", uniqueDropdowns);

  //   return { uniqueDropdowns, menuItems };
  // };
  const userType = auth?.userInfo?.userType;
  const menus = () => {
    const userMenuIds = auth?.userInfo?.menu || [];
    const menuItems =
      (getMenus &&
        getMenus?.data?.data?.filter((menuItem) =>
          userMenuIds.includes(menuItem._id)
        )) ||
      [];
    // console.log("MatchedMenu", menuItems);
    const uniqueDropdowns = [...new Set(menuItems.map((menu) => menu.menu))];
    // console.log("UniqueDropdowns", uniqueDropdowns);
    // console.log("uniqueDropdowns", uniqueDropdowns, menuItems);
    return { uniqueDropdowns, menuItems };
  };

  const handleLinkClick = (event) => {
    console.log("Linkclicked", event);
  };

  const iconMapping = {
    home: "home",
    users: "person",
    master: "engineering",
    customer: "group",
    product: "inventory_2",
    transaction: "point_of_sale",
    profile: "manage_accounts",
    configurations: "settings",
  };

  const AuthItems = () => {
    function togglePanels(newPanel) {
      if (activeDisclosurePanel) {
        if (
          activeDisclosurePanel.key !== newPanel.key &&
          activeDisclosurePanel.open
        ) {
          activeDisclosurePanel.close();
        }
      }

      setActiveDisclosurePanel({
        ...newPanel,
        open: !newPanel.open,
      });
    }

    const currentPath = UseCurrentPath();

    return (
      // <>
      //   {menus() &&
      //     menus().menuItems.map(
      //       (menu) =>
      //         menu.menu === "normal" &&
      //         menu.auth === true && (
      //           <li key={menu._id}>
      //             <Link
      //               to={menu.path}
      //               className="group relative flex items-center gap-2 rounded px-3.5 py-2 duration-200 ease-in-out hover:bg-slate-700 bg-slate-600 text-slate-50 active"
      //               aria-current="page"
      //             >
      //               <span className="material-symbols-rounded">dashboard</span>
      //               <span>{menu.name}</span>
      //             </Link>
      //           </li>
      //         )
      //     )}

      //   {menus() &&
      //     menus().uniqueDropdowns.map((item) => (
      //       <Disclosure as="li" key={item}>
      //         {({ open }) => (
      //           <>
      //             <DisclosureButton className="group relative flex justify-between items-center gap-2 w-full h-9 rounded px-3.5 py-2 text-slate-200 duration-200 ease-in-out hover:bg-slate-700  data-[open]:bg-slate-700">
      //               <span className="material-symbols-rounded">
      //                 shield_person
      //               </span>
      //               <span>
      //                 {item === "profile"
      //                   ? user() && user().firstName + " " + user().lastName
      //                   : item.charAt(0).toUpperCase() + item.substring(1)}
      //               </span>
      //               <span
      //                 className={clsx(
      //                   "material-symbols-rounded ml-auto",
      //                   open && "rotate-180"
      //                 )}
      //               >
      //                 keyboard_arrow_down
      //               </span>
      //             </DisclosureButton>
      //             <DisclosurePanel>
      //               <ul className="pb-4 pt-2 flex flex-col pl-4 space-y-2">
      //                 {menus() &&
      //                   menus().menuItems.map(
      //                     (menu) =>
      //                       menu.menu === item && (
      //                         <li key={menu._id}>
      //                           <Link
      //                             to={menu.path}
      //                             className="group relative flex items-center gap-2 rounded pl-6 py-1 text-slate-200 duration-200 ease-in-out hover:text-blue-400"
      //                           >
      //                             {menu.name}
      //                           </Link>
      //                         </li>
      //                       )
      //                   )}
      //                 {item === "profile" && (
      //                   <>
      //                     <li>
      //                       <button
      //                         onClick={logoutHandler}
      //                         className="group relative flex items-center gap-2 rounded pl-6 py-1 text-slate-200 duration-200 ease-in-out hover:text-blue-400"
      //                       >
      //                         <span>Logout</span>
      //                       </button>
      //                     </li>
      //                   </>
      //                 )}
      //               </ul>
      //             </DisclosurePanel>
      //           </>
      //         )}
      //       </Disclosure>
      //     ))}
      // </>
      <>
        {menus() &&
          menus().menuItems?.map(
            (menu) =>
              menu.menu === "normal" &&
              menu.auth === true && (
                <li key={menu._id}>
                  <Link
                    to={menu.path}
                    title={menu.name}
                    className="group relative flex items-center gap-2 h-7 px-4 py-2 duration-200 ease-in-out hover:bg-slate-700 bg-slate-600 text-slate-50 active"
                    aria-current="page"
                  >
                    <span className="material-symbols-rounded">dashboard</span>
                    <span className="whitespace-nowrap truncate hidden group-[.expanded]/sidebar-wrapper:block">
                      {menu.name}
                    </span>
                  </Link>
                </li>
              )
          )}
        {menus() &&
          menus()
            .uniqueDropdowns?.sort((a, b) => {
              const dropdownOrder = [
                "home",
                "users",
                "master",
                "customer",
                "product",
                "transaction",
                "profile",
                "configurations",
              ];
              return dropdownOrder.indexOf(a) - dropdownOrder.indexOf(b);
            })
            .filter(
              (item) =>
                userType !== "Employee" ||
                (item !== "configurations" && item !== "master")
            )
            .map((item) => {
              const isActive = menus()?.menuItems?.some(
                (menu) => menu.path === currentPath && menu.menu === item
              );
              return (
                <Disclosure
                  as="li"
                  key={item}
                  className={`overflow-hidden ${
                    isActive ? "child-active bg-slate-900" : ""
                  }`}
                  open={isOpen}
                  onChange={setIsOpen}
                >
                  {(panel) => {
                    const { open, focus, close } = panel;
                    if (
                      userType === "Employee" &&
                      ["users", "roles", "permissions", "user roles"].includes(
                        item
                      )
                    ) {
                      return null;
                    }
                    return (
                      <>
                        <DisclosureButton
                          className={`group/item relative flex items-center gap-2 w-full h-9 px-3.5 py-2 text-slate-300 duration-200 ease hover:bg-slate-700 data-[open]:bg-slate-900 ${
                            open ? "bg-slate-800 open" : ""
                          } ${
                            isActive
                              ? "child-active bg-slate-900 font-semibold"
                              : ""
                          }`}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          onClick={() => {
                            if (!open) {
                              setIsOpen(true); // Open the disclosure on click
                            }
                            togglePanels({ ...panel, key: item });
                          }}
                          title={
                            item === "profile"
                              ? user()?.firstName + " " + user()?.lastName
                              : item.charAt(0).toUpperCase() + item.substring(1)
                          }
                        >
                          <span
                            className={`material-symbols-rounded text-slate-300 ${
                              isActive ? "filled" : ""
                            }`}
                          >
                            {iconMapping[item] || "shield_person"}
                          </span>
                          <span className="whitespace-nowrap truncate hidden group-[.expanded]/sidebar-wrapper:block group-hover/sidebar-wrapper:block">
                            {item === "profile"
                              ? user()?.firstName + " " + user()?.lastName
                              : item.charAt(0).toUpperCase() +
                                item.substring(1)}
                          </span>
                          <span
                            className={clsx(
                              "material-symbols-rounded ml-auto !hidden group-[.open]/item:!block group-hover/item:!block",
                              open && "rotate-180"
                            )}
                          >
                            keyboard_arrow_down
                          </span>
                        </DisclosureButton>
                        <DisclosurePanel
                          unmount={true}
                          className={`group-[.expanded]/sidebar-wrapper:pl-5 group-[.collapsed]/sidebar-wrapper:pl-0 text-sm text-gray-500 ${
                            open || isHovered ? "block" : "hidden"
                          }`}
                        >
                          <ul className="pt-2 mb-2 flex flex-col border-l border-slate-700">
                            {menus() &&
                              menus()
                                .menuItems?.map((menu) => {
                                  if (menu.menu === "profile") {
                                    return {
                                      ...menu,
                                      order:
                                        menu.name === "Profile"
                                          ? -1
                                          : menu.order,
                                    };
                                  }
                                  return menu;
                                })
                                ?.sort((a, b) => a.order - b.order)
                                .map((menu) =>
                                  menu.menu === item ? (
                                    <li key={menu._id}>
                                      {menu.name === "Logout" ? (
                                        <button
                                          onClick={logoutHandler}
                                          className={`group relative flex items-center gap-2 -ml-px border-l-2 hover:border-current pl-5 py-1 text-slate-200 duration-200 ease-in-out hover:text-blue-300 ${
                                            currentPath === menu.path
                                              ? "border-current text-blue-300 font-semibold"
                                              : "border-transparent"
                                          }`}
                                          title={menu.name}
                                        >
                                          <span className="whitespace-nowrap truncate hidden group-[.expanded]/sidebar-wrapper:block">
                                            {menu.name}
                                          </span>
                                        </button>
                                      ) : (
                                        <Link
                                          to={menu.path}
                                          onClick={() =>
                                            handleLinkClick(menu.path)
                                          }
                                          className={`group relative flex items-center gap-2 -ml-px h-7 border-l-2 hover:border-current group-[.collapsed]/sidebar-wrapper:pl-1 group-[.expanded]/sidebar-wrapper:pl-5 py-1  duration-200 ease-in-out hover:text-blue-300 ${
                                            currentPath === menu.path
                                              ? "border-current text-blue-300 bg-blue-950 font-semibold"
                                              : "border-transparent text-slate-200"
                                          }`}
                                          title={menu.name}
                                        >
                                          <span className="whitespace-nowrap overflow-hidden group-[.expanded]/sidebar-wrapper:w-full after:content-[''] after:absolute after:right-0 after:top-0 after:w-10 after:h-full after:bg-gradient-to-r after:from-transparent after:to-slate-900">
                                            {menu.name}
                                          </span>
                                        </Link>
                                      )}
                                    </li>
                                  ) : null
                                )}
                          </ul>
                        </DisclosurePanel>
                      </>
                    );
                  }}
                </Disclosure>
              );
            })}
      </>
    );
  };

  return (
    <>
      <div className="flex group-[.collapsed]/sidebar-wrapper:flex-col justify-between items-center overflow-hidden group-[.expanded]/sidebar-wrapper:pl-2.5 py-3 group-[.collapsed]/sidebar-wrapper:h-20">
        <img
          src="/htc-white.svg"
          width="64"
          className="max-w-full hidden group-[.expanded]/sidebar-wrapper:block"
          alt="HTC"
        />
        <img
          src="/h-white.svg"
          width="28"
          className="max-w-full block group-[.expanded]/sidebar-wrapper:hidden"
          alt="HTC"
        />
        <Tooltip content="Toggle Menu" placement="right">
          <button
            type="button"
            className={`inline-flex text-gray-400 rounded-full hover:text-gray-200 focus-visible:ring-4 transition duration-150 ease-linear p-2`}
            aria-label="Close"
            onClick={toggleSidebar}
          >
            <span className="material-symbols-rounded filled">
              {isSidebarOpen ? "keyboard_tab_rtl" : "keyboard_tab"}
            </span>
          </button>
        </Tooltip>
      </div>
      {/* <SimpleBar forceVisible="y" autoHide={false} style={{ maxHeight: `calc(100vh - 136px)` }}> */}
        <div className="no-scrollbar flex flex-col overflow-y-auto duration-200 ease-linear">
          <Menu as="nav" className="mt-auto px-1">
            {/* <MenuButton className="flex items-center gap-2 rounded-full bg-slate-500 px-2.5 h-10 font-semibold text-white focus:outline-none duration-200 ease-out data-[focus]:bg-slate-500 data-[open]:bg-slate-600">
            <span className="material-symbols-rounded">add_circle</span>
            <span className="whitespace-nowrap truncate hidden group-[.expanded]/sidebar-wrapper:block group-hover/sidebar-wrapper:block">
              New
            </span>
          </MenuButton> */}

            <MenuItems
              as="ul"
              transition
              anchor="bottom start"
              className="z-[999] w-64 origin-top-left rounded border border-white/5 bg-slate-700 p-1 text-white transition duration-200 ease-out [--anchor-gap:-40px] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
            >
              <MenuSection>
                <MenuHeading className="flex items-center gap-2 font-bold py-2 px-3 text-sm text-slate-400">
                  <span>Product</span>
                </MenuHeading>
                <MenuItem
                  as="a"
                  className="flex items-center py-2 px-3 gap-2 text-slate-200 rounded duration-200 ease-in-out data-[focus]:text-blue-300 data-[focus]:bg-blue-300/10"
                >
                  <span className="material-symbols-rounded">add</span>
                  <span>Brand</span>
                </MenuItem>
                <MenuItem
                  as="a"
                  className="flex items-center py-2 px-3 gap-2 text-slate-200 rounded duration-200 ease-in-out data-[focus]:text-blue-300 data-[focus]:bg-blue-300/10"
                >
                  <span className="material-symbols-rounded">add</span>
                  <span>Category</span>
                </MenuItem>
                <MenuItem
                  as="a"
                  className="flex items-center py-2 px-3 gap-2 text-slate-200 rounded duration-200 ease-in-out data-[focus]:text-blue-300 data-[focus]:bg-blue-300/10"
                >
                  <span className="material-symbols-rounded">add</span>
                  <span>Item group</span>
                </MenuItem>
                <MenuItem
                  as="a"
                  href="/admin/auth/items"
                  className="flex items-center py-2 px-3 gap-2 text-slate-200 rounded duration-200 ease-in-out data-[focus]:text-blue-300 data-[focus]:bg-blue-300/10"
                >
                  <span className="material-symbols-rounded">add</span>
                  <span>Item</span>
                </MenuItem>
              </MenuSection>
              <MenuSeparator as="div" className="my-1 h-px bg-white/5" />
            </MenuItems>
          </Menu>
          <nav className="py-4">
            <ul className="flex flex-col gap-px">
              {userInfo ? AuthItems() : guestItems()}

              {/* <Disclosure as="li">
                <DisclosureButton className="group relative flex justify-between items-center gap-2 rounded py-2 px-4 text-slate-200 duration-200 ease-in-out hover:bg-slate-700 ">
                  <span className="material-symbols-rounded">
                    shield_person
                  </span>
                  <span>Administration</span>
                  <span className="material-symbols-rounded ml-auto">
                    keyboard_arrow_down
                  </span>
                </DisclosureButton>
                <DisclosurePanel>
                  <ul className="mb-3 flex flex-col pl-4 space-y-3">
                    <li>
                      <a
                        className="group relative flex items-center gap-2 rounded pl-8 text-slate-200 duration-200 ease-in-out hover:text-blue-400"
                        href="#"
                      >
                        State & City
                      </a>
                    </li>
                    <li>
                      <a
                        className="group relative flex items-center gap-2 rounded pl-8 text-slate-200 duration-200 ease-in-out hover:text-blue-400"
                        href="#"
                      >
                        Department & Designation
                      </a>
                    </li>
                    <li>
                      <a
                        className="group relative flex items-center gap-2 rounded pl-8 text-slate-200 duration-200 ease-in-out hover:text-blue-400"
                        href="#"
                      >
                        Unit & Unit Conversion
                      </a>
                    </li>
                    <li>
                      <a
                        className="group relative flex items-center gap-2 rounded pl-8 text-slate-200 duration-200 ease-in-out hover:text-blue-400"
                        href="#"
                      >
                        Service Type
                      </a>
                    </li>
                    <li>
                      <a
                        className="group relative flex items-center gap-2 rounded pl-8 text-slate-200 duration-200 ease-in-out hover:text-blue-400"
                        href="#"
                      >
                        Users
                      </a>
                    </li>
                    <li>
                      <a
                        className="group relative flex items-center gap-2 rounded pl-8 text-slate-200 duration-200 ease-in-out hover:text-blue-400"
                        href="#"
                      >
                        Company Branch
                      </a>
                    </li>
                    <li>
                      <a
                        className="group relative flex items-center gap-2 rounded pl-8 text-slate-200 duration-200 ease-in-out hover:text-blue-400"
                        href="#"
                      >
                        Channel Partner
                      </a>
                    </li>
                    <li>
                      <a
                        className="group relative flex items-center gap-2 rounded pl-8 text-slate-200 duration-200 ease-in-out hover:text-blue-400"
                        href="#"
                      >
                        Rights
                      </a>
                    </li>
                    <li>
                      <a
                        className="group relative flex items-center gap-2 rounded pl-8 text-slate-200 duration-200 ease-in-out hover:text-blue-400"
                        href="#"
                      >
                        Reset Password
                      </a>
                    </li>
                    <li>
                      <a
                        className="group relative flex items-center gap-2 rounded pl-8 text-slate-200 duration-200 ease-in-out hover:text-blue-400"
                        href="#"
                      >
                        Configuration
                      </a>
                    </li>
                  </ul>
                </DisclosurePanel>
              </Disclosure>
              <li>
                <a
                  className="group relative flex items-center gap-2 rounded py-2 px-4 text-slate-200 duration-200 ease-in-out hover:bg-slate-700 "
                  href="/profile"
                >
                  <span className="material-symbols-rounded">business</span>
                  <span>Company</span>
                </a>
              </li>
              <li>
                <a
                  aria-current="page"
                  className="group relative flex items-center gap-2 rounded py-2 px-4 text-slate-200 duration-200 ease-in-out hover:bg-slate-700  active"
                  href="/"
                >
                  <span className="material-symbols-rounded">
                    manage_accounts
                  </span>
                  <span>Account</span>
                </a>
              </li>
              <li>
                <a
                  aria-current="page"
                  className="group relative flex items-center gap-2 rounded py-2 px-4 text-slate-200 duration-200 ease-in-out hover:bg-slate-700  active"
                  href="/"
                >
                  <span className="material-symbols-rounded">inventory_2</span>
                  <span>Product</span>
                </a>
              </li>
              <li>
                <a
                  aria-current="page"
                  className="group relative flex items-center gap-2 rounded py-2 px-4 text-slate-200 duration-200 ease-in-out hover:bg-slate-700  active"
                  href="/"
                >
                  <span className="material-symbols-rounded">contract</span>
                  <span>Transaction</span>
                </a>
              </li>
              <li>
                <a
                  aria-current="page"
                  className="group relative flex items-center gap-2 rounded py-2 px-4 text-slate-200 duration-200 ease-in-out hover:bg-slate-700  active"
                  href="/"
                >
                  <span className="material-symbols-rounded">analytics</span>
                  <span>Reports</span>
                </a>
              </li> */}
            </ul>
          </nav>
        </div>
      {/* </SimpleBar> */}
      <Menu as="nav" className="mt-auto p-2">
        <MenuButton className="flex items-center w-full gap-2 rounded-full p-1 font-semibold text-white focus:outline-none duration-200 ease-out data-[hover]:bg-slate-700 data-[open]:bg-slate-700 data-[focus]:outline-1 data-[focus]:outline-white">
          <span className="flex-[0_0_auto] flex items-center justify-center w-8 h-8 rounded-full bg-blue-800">
            JC
          </span>
          <span className="whitespace-nowrap truncate hidden group-[.expanded]/sidebar-wrapper:block group-hover/sidebar-wrapper:block">
            John Chinnathambi
          </span>
        </MenuButton>

        <MenuItems
          as="ul"
          transition
          anchor="right end"
          className="z-[999] w-64 origin-bottom-left rounded border border-white/5 bg-slate-700 p-1 text-white transition duration-200 ease-out [--anchor-gap:8px] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
        >
          <MenuSection>
            <MenuHeading className="flex flex-col py-2 px-3 text-sm text-slate-300">
              <span>Signed in as</span>
              <span className="font-semibold">php.john.c@gmail.com</span>
            </MenuHeading>
            <MenuSeparator as="div" className="my-1 h-px bg-white/5" />

            <MenuItem
              as="a"
              href="account/profile"
              className="block py-2 px-3 text-slate-200 rounded duration-200 ease-in-out data-[focus]:text-blue-300 data-[focus]:bg-blue-300/10"
            >
              Profile
            </MenuItem>
            <MenuItem
              as="a"
              href="admin/auth/change-password"
              className="block py-2 px-3 text-slate-200 rounded duration-200 ease-in-out data-[focus]:text-blue-300 data-[focus]:bg-blue-300/10"
            >
              Change Password
            </MenuItem>
          </MenuSection>
          <MenuSeparator as="div" className="my-1 h-px bg-white/5" />
          <MenuSection>
            <MenuItem
              as="a"
              onClick={logoutHandler}
              className="cursor-pointer block py-2 px-3 text-slate-200 rounded duration-200 ease-in-out data-[focus]:bg-red-300/10 data-[focus]:text-red-300"
            >
              Logout
            </MenuItem>
          </MenuSection>
        </MenuItems>
      </Menu>
    </>
  );
};

export default Navigation;
