import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const Home = () => {
  const [greeting, setGreeting] = useState("");
  const [icon, setIcon] = useState("");
  const [userName, setUserName] = useState("User");
  const [userType, setUserType] = useState("Guest");
  const currentDate = new Date();

  useEffect(() => {
    // Fetch and parse `userInfo` from localStorage
    const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
    const firstName = userInfo.firstName || "User";
    const lastName = userInfo.lastName || "";
    const userTypeFromStorage = userInfo.userType || "Guest";

    setUserName(`${firstName} ${lastName}`);
    setUserType(userTypeFromStorage);

    // Determine the greeting and icon based on the time of day
    const hour = currentDate.getHours();

    if (hour >= 5 && hour < 12) {
      setGreeting(`Good Morning, ${firstName}`);
      setIcon("/morning.png");
    } else if (hour >= 12 && hour < 17) {
      setGreeting(`Good Afternoon, ${firstName}`);
      setIcon("/afternoon.png");
    } else if (hour >= 17 && hour < 21) {
      setGreeting(`Good Evening, ${firstName}`);
      setIcon("/evening.png");
    } else {
      setGreeting(`Welcome, ${firstName}`);
      setIcon("/night.png");
    }
  }, [currentDate]);

  return (
    <>
      <Helmet>
        <title>Dashboard | HTC Accounting</title>
        <meta
          property="og:title"
          content="Dashboard - HTC Accounting"
          key="title"
        />
      </Helmet>
      <div className="page-wrapper flex flex-col relative">
        <div className="relative overflow-hidden -ml-3 -mr-3 -mt-3">
          <img
            src="/abstract-bg-1.png"
            alt=""
            className="w-full h-full opacity-40 object-cover absolute object-center"
          />
          <div className="backdrop-blur-lg">
            <div className="w-full max-w-[1660px] mx-auto">
              <div className="flex items-center py-5">
                <div className="select-none pointer-events-none">
                  <img src={icon} alt="Greeting Icon" className="w-18 h-18 " />
                </div>
                <div className="ml-1">
                  <h1 className="font-bold text-xl text-gray-800">
                    {greeting}
                  </h1>
                  <p className="text-sm text-gray-800 font-semibold mb-1">
                    {userType}
                  </p>
                  <p className="text-gray-600">
                    {currentDate.toLocaleDateString("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full max-w-[1660px] mx-auto mt-4">
          <div className="grid grid-cols-12 gap-4 mb-4">
            <div className="col-span-2 flex items-start gap-3 bg-cyan-50 shadow-sm shadow-cyan-100 hover:shadow-cyan-300 transition-all rounded-md border border-cyan-200 p-3">
              <div className="inline-flex items-center bg-cyan-100 text-cyan-600 p-2 rounded-full">
                <span className="material-symbols-rounded icon-24">group</span>
              </div>
              <div>
                <div className="font-bold text-2xl text-cyan-600">572</div>
                <div className="text-base text-black/50">Total Customers</div>
              </div>
            </div>

            <div className="col-span-2 flex items-start gap-3 bg-sky-50 shadow-sm shadow-sky-100 hover:shadow-sky-300 transition-all rounded-md border border-sky-200 p-3">
              <div className="inline-flex items-center bg-sky-100 text-sky-600 p-2 rounded-full">
                <span className="material-symbols-rounded icon-24">
                  person_check
                </span>
              </div>
              <div>
                <div className="font-bold text-2xl text-sky-600">120</div>
                <div className="text-base text-black/50">
                  Total Active Users
                </div>
              </div>
            </div>
            <div className="col-span-2 flex items-start gap-3 bg-green-50 shadow-sm shadow-green-100 hover:shadow-green-300 transition-all rounded-md border border-green-200 p-3">
              <div className="inline-flex items-center bg-green-100 text-green-600 p-2 rounded-full">
                <span className="material-symbols-rounded icon-24">
                  currency_rupee_circle
                </span>
              </div>
              <div>
                <div className="font-bold text-2xl text-green-600">
                  &#8377; 3,21,654
                </div>
                <div className="text-base text-black/50">Total Revenue</div>
              </div>
            </div>
            <div className="col-span-2 flex items-start gap-3 bg-violet-50 shadow-sm shadow-violet-100 hover:shadow-violet-300 transition-all rounded-md border border-violet-200 p-3">
              <div className="inline-flex items-center bg-violet-100 text-violet-600 p-2 rounded-full">
                <span className="material-symbols-rounded icon-24">
                  apartment
                </span>
              </div>
              <div>
                <div className="font-bold text-2xl text-violet-600">64</div>
                <div className="text-base text-black/50">Total Companies</div>
              </div>
            </div>
            <div className="col-span-2 flex items-start gap-3 bg-rose-50 shadow-sm shadow-rose-100 hover:shadow-rose-300 transition-all rounded-md border border-rose-200 p-3">
              <div className="inline-flex items-center bg-rose-100 text-rose-600 p-2 rounded-full">
                <span className="material-symbols-rounded icon-24">
                  inventory_2
                </span>
              </div>
              <div>
                <div className="font-bold text-2xl text-rose-600">321654</div>
                <div className="text-base text-black/50">Total Products</div>
              </div>
            </div>
            <div className="col-span-2 flex items-start gap-3 bg-orange-50 shadow-sm shadow-orange-100 hover:shadow-orange-300 transition-all rounded-md border border-orange-200 p-3">
              <div className="inline-flex items-center bg-orange-100 text-orange-600 p-2 rounded-full">
                <span className="material-symbols-rounded icon-24">
                  percent
                </span>
              </div>
              <div>
                <div className="font-bold text-2xl text-orange-600">
                  &#8377; 1,24,654
                </div>
                <div className="text-base text-black/50">GST to Pay</div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 mb-4">
            <div className="col-span-6 bg-white shadow-md rounded-md border">
              <div className="flex items-center justify-between gap-2 px-3">
                <div className="text-lg font-bold my-3">New Customers</div>
                <div className="">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center gap-1 text-blue-600 bg-white hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm px-3 py-0 h-8 text-center "
                  >
                    <span>View all</span>
                    <span className="material-symbols-rounded">
                      arrow_forward
                    </span>
                  </button>
                </div>
              </div>
              <div className="relative overflow-x-auto border-t border-slate-300 mb-3">
                <table className="w-full text-sm text-left text-gray-800 table-fixed">
                  <thead className="text-xs text-slate-800 bg-slate-100 border-b-2 border-slate-300">
                    <tr>
                      <th className="px-3 py-3">Customer Name</th>
                      <th className="px-3 py-3" style={{ width: "100px" }}>
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b last:border-0 hover:bg-slate-100">
                      <td className="px-3 py-2 truncate">XYZ Company Pvt. ltd.</td>
                      <td className="px-3 py-2 truncate">
                        <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                          Active
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b last:border-0 hover:bg-slate-100">
                      <td className="px-3 py-2 truncate">XYZ Company Pvt. ltd.</td>
                      <td className="px-3 py-2 truncate">
                        <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                          Active
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b last:border-0 hover:bg-slate-100">
                      <td className="px-3 py-2 truncate">XYZ Company Pvt. ltd.</td>
                      <td className="px-3 py-2 truncate">
                        <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                          Active
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b last:border-0 hover:bg-slate-100">
                      <td className="px-3 py-2 truncate">XYZ Company Pvt. ltd.</td>
                      <td className="px-3 py-2 truncate">
                        <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                          Active
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b last:border-0 hover:bg-slate-100">
                      <td className="px-3 py-2 truncate">XYZ Company Pvt. ltd.</td>
                      <td className="px-3 py-2 truncate">
                        <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                          Active
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b last:border-0 hover:bg-slate-100">
                      <td className="px-3 py-2 truncate">XYZ Company Pvt. ltd.</td>
                      <td className="px-3 py-2 truncate">
                        <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                          Active
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-span-6 bg-white shadow-md rounded-md border">
              <div className="flex items-center px-3 justify-between gap-2">
                <div className="text-lg font-bold my-3">New Users</div>
                <div className="">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center gap-1 text-blue-600 bg-white hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white font-semibold rounded text-sm px-3 py-0 h-8 text-center "
                  >
                    <span>View all</span>
                    <span className="material-symbols-rounded">
                      arrow_forward
                    </span>
                  </button>
                </div>
              </div>
              <div className="relative overflow-x-auto border-t border-slate-300 mb-3">
                <table className="w-full text-sm text-left text-gray-800 table-fixed">
                  <thead className="text-xs text-slate-800 bg-slate-100 border-b-2 border-slate-300">
                    <tr>
                      <th className="px-3 py-3">Customer Name</th>
                      <th className="px-3 py-3" style={{ width: "100px" }}>
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b last:border-0 hover:bg-slate-100">
                      <td className="px-3 py-2 truncate">User XYZ</td>
                      <td className="px-3 py-2 truncate">
                        <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                          Active
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b last:border-0 hover:bg-slate-100">
                      <td className="px-3 py-2 truncate">User XYZ</td>
                      <td className="px-3 py-2 truncate">
                        <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                          Active
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b last:border-0 hover:bg-slate-100">
                      <td className="px-3 py-2 truncate">User XYZ</td>
                      <td className="px-3 py-2 truncate">
                        <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                          Active
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b last:border-0 hover:bg-slate-100">
                      <td className="px-3 py-2 truncate">User XYZ</td>
                      <td className="px-3 py-2 truncate">
                        <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                          Active
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b last:border-0 hover:bg-slate-100">
                      <td className="px-3 py-2 truncate">User XYZ</td>
                      <td className="px-3 py-2 truncate">
                        <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                          Active
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b last:border-0 hover:bg-slate-100">
                      <td className="px-3 py-2 truncate">User XYZ</td>
                      <td className="px-3 py-2 truncate">
                        <span className="text-xs bg-green-100 border border-green-300 text-green-700 rounded-full px-2 py-1">
                          Active
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
