import React, { useState } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
} from '@floating-ui/react';

const Tooltip = ({ children, content, position = 'top' }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { x, y, refs, context } = useFloating({
    placement: position,
    middleware: [offset(5), flip(), shift()],
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const hover = useHover(context);
  const focusInteraction = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focusInteraction,
    dismiss,
    role,
  ]);

  return (
    <>
      {/* Directly render the children without a wrapper */}
      {React.cloneElement(children, {
        ref: refs.setReference,
        ...getReferenceProps(),
      })}
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{
              position: 'absolute',
              top: y ?? 0,
              left: x ?? 0,
              fontSize: '12px',
              backgroundColor: '#212121',
              color: 'white',
              padding: '2px 6px',
              borderRadius: '4px',
              zIndex: 9999,
            }}
            {...getFloatingProps()}
          >
            {content}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export default Tooltip;
